import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'filterByInitialOrPart'
})
export class FilterByInitialOrPartPipe implements PipeTransform {

  transform(input: Array<string>, filter: string): Array<string> {

    if (!Array.isArray(input)) {
      return input;
    }

    let r: Array<string> = new Array<string>();

    for (const value of input) {

      if(!filter){

        r.push(value);
      
      }else{

        let v: string = value.toUpperCase();
        let f: string = filter.toUpperCase();

        if(f.length< 3 && v.indexOf(f)==0){
          r.push(value);
        }else if(f.length >= 3 && v.indexOf(f)>=0){
          r.push(value);
        }

      }
      
    }

    return r;

  }

}
