import { Component, OnInit, ViewChild } from '@angular/core';
import { LibraryExplorerLayoutComponent } from '../../layouts/library-explorer-layout/library-explorer-layout.component';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MovieLibraryStateService } from 'src/app/services/library-state/movie-library-state.service';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { MatSelectChange } from '@angular/material/select';
import { VideoResolutions } from '../../api.client';
import { LibraryExplorer2LayoutComponent } from '../../layouts/library-explorer2-layout/library-explorer2-layout.component';

@Component({
  selector: 'app-movie-library',
  templateUrl: './movie-library.component.html',
  styleUrls: ['./movie-library.component.scss']
})
export class MovieLibraryComponent implements OnInit {

  public videoResolution: VideoResolutions;
  public videoResolutions = Object.keys( VideoResolutions);

  @ViewChild(LibraryExplorer2LayoutComponent) _libraryExplorerLayout: LibraryExplorer2LayoutComponent;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public stateService: MovieLibraryStateService) {

  }

  ngOnInit() {
    this.videoResolution = this.stateService.contentItemsVideoResolution;
    if (this.stateService.contentItems.length === 0) {
      this.stateService.contentItemsTotalCount = 999999;
      this.stateService.contentItemsList();
    }
  }

  public onScrolledToBottom() {
    this.stateService.contentItemsList();
  }

  public onSearchTextChanged(searchText: string) {
    this.stateService.contentItemsSearchText = searchText;
    this.stateService.contentItemsList(true);
    this._libraryExplorerLayout.resetScrollPosition();
  }

  public onBasePathChange(event: MatSelectChange) {
    this.stateService.currentBasePath = event.value;
    this.stateService.contentItemsList(true);
    this._libraryExplorerLayout.resetScrollPosition();
  }

  public onViewSliderChange(event: MatSlideToggleChange) {
    this.stateService.contentItemsChangeSelectedView(event.source.name, event.checked);
  }

  public onMarkAsButtonClick(id: number, completed: boolean) {
    this.stateService.contentItemMarkAs(id, completed);
  }

  public onForceInspectButtonClick(id: number) {
    this.stateService.contentItemForceDeepScan(id);
  }

  public onDisplayModeChanged(mode: string) {
    this.stateService.currentDisplayMode = mode;
  }

  public onRemoveButtonClick(id: number) {
    this.stateService.contentItemRemove(id);
  }

  public onSearchTMDBButtonClick(id: number) {
    this.stateService.searchTmdb(id);
  }

  public onToDeviceSelectionChange(event: MatOptionSelectionChange, id:number) {
    if (event.isUserInput) {
      this.stateService.toggleToDeviceState(event.source.selected, id, event.source.value);
    }
  }

  public onVideoResolutionChange(event: MatSelectChange) {
    this.stateService.contentItemsVideoResolution = event.value;
    this.stateService.contentItemsList(true);
    this._libraryExplorerLayout.resetScrollPosition();
  }

  public compareToDevices(fromOption: number, fromSelection: number):boolean {
    return fromOption === fromSelection;
  }

}
