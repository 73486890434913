import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Track } from 'src/app/api.client';
import { GlobalStateService } from './global-state.service';
import { MusicPlayerStateModel, MusicPlayerStatus } from '../models/music-player-state.model';

@Injectable()
export class MusicPlayerStateService {

  public stateModel: MusicPlayerStateModel = new MusicPlayerStateModel();

  private stateSubject: BehaviorSubject<MusicPlayerStateModel> = new BehaviorSubject(this.stateModel);
  public stateChanged: Observable<MusicPlayerStateModel> = this.stateSubject.asObservable();

  public tracks: Array<Track> = [];
  public currentTrackId: number;

  public get isPlaying() {
    return this.stateModel.status == MusicPlayerStatus.playing;
  }

  constructor(public globalStateService: GlobalStateService) {
  }

  public changeState(track: Track, status: MusicPlayerStatus) {
    if ((this.stateModel.currentTrack != track || this.stateModel.currentTrack.id != track.id) || this.stateModel.status != status) {
      this.stateModel.currentTrack = track;
      this.stateModel.status = status;
      this.stateSubject.next(this.stateModel);
    }
  }

  public changeTrack(track: Track) {
    if (this.stateModel.currentTrack != track || this.stateModel.currentTrack.id != track.id) {
      this.stateModel.currentTrack = track;
      this.stateSubject.next(this.stateModel);
    }
  }

  public changeStatus(status: MusicPlayerStatus) {
    if (this.stateModel.status != status) {
      this.stateModel.status = status;
      this.stateSubject.next(this.stateModel);
    }
  }

  public clear() {
    this.stateModel = new MusicPlayerStateModel();
    this.stateSubject.next(this.stateModel);
  }

  public next() {
    let index: number = this.tracks.findIndex(o => o.id == this.currentTrackId);
    if (index > -1) {
      index++;
      if (this.tracks.length > index) {
        let track: Track = this.tracks[index];
        this.currentTrackId = track.id;
        this.changeState(track, MusicPlayerStatus.playing);
      }
    }
  }

}
