import { OnInit } from '@angular/core';
import { Directive, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throttleTime, debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[scrollEvent]'
})
export class ScrollEventDirective implements OnInit {

  @Input() topOffset: number = 100;
  @Input() bottomOffset: number = 100;
  @Input() eventDebounceTime: number = 250;

  @Output() onscroll: EventEmitter<ScrollEvent> = new EventEmitter<ScrollEvent>();

  private scrollSubject = new Subject<ScrollEvent>();
  private scrollObservable: Observable<ScrollEvent>;

  constructor() {
  }

  ngOnInit(): void {
    this.scrollObservable = this.scrollSubject.asObservable().pipe(debounceTime(this.eventDebounceTime))
    this.scrollObservable.subscribe((o: ScrollEvent) => { this.onscroll.emit(o) });
  }

  @HostListener('scroll', ['$event'])
  scrolled($event) {
    const target = $event.target;
    const scrollPosition = target.scrollHeight - target.scrollTop;
    const offsetHeight = target.offsetHeight;
    const isReachingTop = target.scrollTop < this.topOffset;
    const isReachingBottom = (scrollPosition - offsetHeight) < this.bottomOffset;
    this.scrollSubject.next({
      isReachingBottom,
      isReachingTop,
      originalEvent: $event,
      isWindowEvent: false
    });
  }

  @HostListener('window:scroll', ['$event'])
  windowScrolled($event) {
    const target = $event.target;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const isReachingTop = scrollTop < this.topOffset;
    const isReachingBottom = (target.body.offsetHeight - (window.innerHeight + scrollTop)) < this.bottomOffset;
    this.scrollSubject.next({
      isReachingBottom,
      isReachingTop,
      originalEvent: $event,
      isWindowEvent: true
    });
  }

}


export interface ScrollEvent {
  isReachingBottom: boolean,
  isReachingTop: boolean,
  originalEvent: any,
  isWindowEvent: boolean
}
