import { Component, OnInit, ViewChild } from '@angular/core';
import { LibraryExplorerLayoutComponent } from '../../../layouts/library-explorer-layout/library-explorer-layout.component';
import { ConfirmDialogComponent } from 'src/app/bits/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ComicLibraryClient, Series, SeriesPageable, ComicVineVolume } from 'src/app/api.client';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { SearchOnComicVineDialogComponent } from 'src/app/bits/search-on-comicvine-dialog/search-on-comicvine-dialog.component';
import { SearchOnMetadataProviderDialogModel } from 'src/app/models/search-on-metadata-provider.model';
import { CurrentProfileSettingsService } from '../../../services/current-profile-settings.service';
import { UpdatedValueModel } from '../../../layouts/item-layout/item-layout.component';
import { ComicLibraryStateService } from '../../../services/library-state/comic-library-state.service';
import { ActivatedRoute } from '@angular/router';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit {

  @ViewChild(LibraryExplorerLayoutComponent) _libraryExplorerLayout: LibraryExplorerLayoutComponent;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public apiClient: ComicLibraryClient,
    public dialog: MatDialog,
    public screenDetectionService: ScreenDetectionService,
    public route: ActivatedRoute,
    public stateService: ComicLibraryStateService,
    public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    if (this.stateService.containerItems.length === 0) {
      this.stateService.containerItemsTotalCount = 999999;
      this.stateService.containerItemsList();
    }
  }

  public onScrolledToBottom() {
    this.stateService.containerItemsList();
  }

  public onSearchTextChanged(searchText: string) {
    this.stateService.containerItemsSearchText = searchText;
    this.stateService.containerItemsList(true);
  }

  public onViewSliderChange(event: MatSlideToggleChange) {
    this.stateService.containerItemsChangeSelectedView(event.source.name, event.checked);
  }

  public onDisplayModeChanged(mode: string) {
    this.stateService.currentDisplayMode = mode;
  }

  public onRemoveButtonClick(id: number) {
    this.stateService.containerItemRemoveById(id);
  }

  public onMarkAsButtonClick(id: number, completed: boolean) {
    this.stateService.containerItemMarkAs(id, completed);
  }
  
  public onForceInspectButtonClick(id: number) {
    this.stateService.containerItemForceDeepScan(id);
  }

  public onEditableUpdated(id: number, updatedValueModel: UpdatedValueModel) {
    this.stateService.containerItemUpdateProperty(id, updatedValueModel);
  }

  public onChangeMonitorButtonClick(id: number, value: boolean) {
    this.stateService.toggleMonitor(id, value);
  }

  public onChangeIgnoreScrapingButtonClick(id: number, value: boolean) {
    this.stateService.toggleIgnoreScraping(id, value);
  }

  public onSearchComicVineButtonClick(id: number) {
    this.stateService.searchComicVine(id);
  }

  public onToDeviceSelectionChange(event: MatOptionSelectionChange, id: number) {
    if (event.isUserInput) {
      this.stateService.toggleToDeviceState(event.source.selected, id, event.source.value);
    }
  }

  public compareToDevices(fromOption: number, fromSelection: number): boolean {
    return fromOption === fromSelection;
  }

}
