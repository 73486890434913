export class ConfirmDialogModel {

  public icon:string;
  public mainContent:string;
  public secondaryContent?: string;
  public data?: any;

  constructor(icon:string, mainContent:string, secondaryContent?:string, data?:any){
    this.icon = icon;
    this.mainContent = mainContent;
    this.secondaryContent = secondaryContent;
    this.data = data;
  }

}
