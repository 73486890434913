import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'getInitials'
})
export class GetInitialsPipe implements PipeTransform {

  transform(input: Array<string>): Array<string> {

    if (!Array.isArray(input)) {
      return input;
    }

    let r: Array<string> = new Array<string>();

    for (const value of input) {

      if (!value) {
        continue;
      }

      const initial = value.substr(0, 1).toUpperCase();

      if (r.indexOf(initial) < 0) {
        r.push(initial)
      }
      
    }

    return r;

  }

}
