import { Injectable } from '@angular/core';
import { GlobalStateService } from '../global-state.service';
import { Book, BookLibraryClient } from 'src/app/api.client';
import { MatDialog } from '@angular/material/dialog';
import { ScreenDetectionService } from '../screen-detection.service';
import { BaseLibraryStateService } from './base-library-state.service';
import { NotificationService } from '../notification.service';

@Injectable()
export class BookLibraryStateService extends BaseLibraryStateService<never, Book> {

  public contentItemsViews: string[] = [
      "not-read",
      "reading",
      "published"
    ];

  private _bookLibraryClient: BookLibraryClient;

  constructor(
    globalStateService: GlobalStateService,
    client: BookLibraryClient,
    _notificationService: NotificationService,
    dialog: MatDialog,
    screenDetectionService: ScreenDetectionService
  ) {
    super(globalStateService, "BOOKLIBRARY", _notificationService, dialog, screenDetectionService, client);
    this._bookLibraryClient = client;
  }

}

