import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { GlobalStateService } from '../services/global-state.service';

@Injectable()
export class AuthGuard  {

  constructor(private router: Router, public globalStateService:GlobalStateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if(!this.globalStateService.sessionKey){
      this.router.navigateByUrl('/login');
    }

    return true;

  }
}