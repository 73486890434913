import { Component, OnInit, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { BaseItem } from '../../api.client';

@Component({
  selector: 'app-item-layout',
  templateUrl: './item-layout.component.html',
  styleUrls: ['./item-layout.component.scss']
})
export class ItemLayoutComponent {

  @Input() layout: string;

  @Input() enabledEditables: string[] = [];

  @Input() item: any;

  //@Output() moreClicked: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
  @Output() editableUpdated: EventEmitter<UpdatedValueModel> = new EventEmitter<UpdatedValueModel>();

  public isMore = false;

  constructor(
    public ref: ElementRef, 
    public screenDetectionService: ScreenDetectionService,
    public globalStateService: GlobalStateService) { }

  onMoreClicked() {
    this.isMore = !this.isMore;
    if (!this.screenDetectionService.isMobile) {
      setTimeout(() => {
        this.ref.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  }

  onEditableUpdated(propertyName: string, value: string) {
    this.editableUpdated.emit({ propertyName: propertyName, value: value });
  }

  editableIsEnabled(name: string) {
    return this.enabledEditables.indexOf(name) > -1;
  }

}

export class UpdatedValueModel {
  public propertyName: string;
  public value: string
}
