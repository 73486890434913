import { Component, OnInit, ViewChild } from '@angular/core';
import { LibraryExplorerLayoutComponent } from '../../../layouts/library-explorer-layout/library-explorer-layout.component';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { ShowLibraryClient, ShowPageable } from 'src/app/api.client';
import { ShowLibraryStateService } from 'src/app/services/library-state/show-library-state.service';
import { CurrentProfileSettingsService } from '../../../services/current-profile-settings.service';

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.scss']
})
export class ShowsComponent implements OnInit {

  public initials: string[];

  @ViewChild(LibraryExplorerLayoutComponent) _libraryExplorerLayout: LibraryExplorerLayoutComponent;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public apiClient: ShowLibraryClient,
    public dialog: MatDialog,
    public screenDetectionService: ScreenDetectionService,
    public stateService: ShowLibraryStateService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (this.stateService.containerItems.length === 0) {
      this.stateService.containerItemsTotalCount = 999999;
      this.stateService.containerItemsList();
    }
  }

  public onScrolledToBottom() {
    this.stateService.containerItemsList();
  }

  public onDisplayModeChanged(mode: string) {
    this.stateService.currentDisplayMode = mode;
  }

  public onMarkAsButtonClick(id: number, completed: boolean) {
    this.stateService.containerItemMarkAs(id, completed);
  }
  
  public onForceInspectButtonClick(id: number) {
    this.stateService.containerItemForceDeepScan(id);
  }

  public onToDeviceSelectionChange(event: MatOptionSelectionChange, id:number) {
    if (event.isUserInput) {
      this.stateService.toggleToDeviceState(event.source.selected, id, event.source.value);
    }
  }

  public compareToDevices(fromOption: number, fromSelection: number): boolean {
    return fromOption === fromSelection;
  }

}
