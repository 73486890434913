import { Track } from 'src/app/api.client';

export class MusicPlayerStateModel {

  public status:MusicPlayerStatus = MusicPlayerStatus.stopped;
  public currentTrack:Track = null;

}

export enum MusicPlayerStatus {
  playing =1 ,
  stopped = 2,
  paused = 3,
  ended=4
}
