import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss'],
})

export class EditableComponent implements OnInit {

  @Input() data: any;
  @Input() type: string = "text";
  @Input() enabled: boolean= false;
  @Output() editableUpdated: EventEmitter<any> = new EventEmitter<any>();

  editMode = false;
  constructor() { }

  ngOnInit() { }

  onConfirmButtonClick(value: string) {
    this.editMode = false;
    if (this.data === value) {
      return;
    }
    this.data = value;
    this.editableUpdated.emit(this.data);
  }

}
