 import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import Enumerable from 'linq';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Hardware, LongRunningOperation, Message, WellKnownEvents } from '../api.client';
import { GlobalStateService } from './global-state.service';

@Injectable({
  providedIn: 'root'
})
export class WsClientService {

  public messagesCount: Subject<number> = new Subject<number>();
  public runningOperationsCount: Subject<number> = new Subject<number>();
  public hardwareStatus: Subject<Hardware[]> = new Subject<Hardware[]>();

  private _hubConnection: signalR.HubConnection

  constructor(
    private _globalStateService: GlobalStateService
  ) {
    this.startConnection();
    this.addListeners();
  }

  private startConnection = () => {

    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/ws', { headers: { "X-Alessandria-SessionKey": this._globalStateService.sessionKey} })
      .withAutomaticReconnect(Enumerable.range(1000, 100, 2000).toArray())
      .build();

    this._hubConnection
      .start()
      .catch(err => console.log('Error while starting connection: ' + err))

  }

  private addListeners = () => {

    this._hubConnection.on(WellKnownEvents.MessagesCount, (o: number) => {
      this.messagesCount.next(o);
    });

    this._hubConnection.on(WellKnownEvents.RunningOperationsCount, (o: number) => {
      this.runningOperationsCount.next(o);
    });

    this._hubConnection.on(WellKnownEvents.HardwareStatus, (o: Hardware[]) => {
      this.hardwareStatus.next(o);
    });

  }

}
