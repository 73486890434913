import { Component, OnInit } from '@angular/core';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PushSettings, Device, LinkedIdentity } from '../../api.client';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent {

  public avatars: Map<string, string> = new Map(
    [
      ["Air hostess", "airhostess.png"],
      ["Alien", "alien.png"],
      ["Alieness", "alieness.png"],
      ["Angel", "angel.png"],
      ["Aphrodite", "aphrodite.png"],
      ["Astronaut", "astronaut.png"],
      ["Canary", "canary.png"],
      ["Captainess", "captainess.png"],
      ["Catwoman", "catwoman.png"],
      ["Contractor", "contractor.png"],
      ["Dandy", "dandy.png"],
      ["Devil", "devil.png"],
      ["Doctor", "doctor.png"],
      ["Fairy", "fairy.png"],
      ["Female dummy", "female_dummy.png"],
      ["Gangster", "gangster.png"],
      ["King", "king.png"],
      ["Maid", "maid.png"],
      ["Male dummy", "male_dummy.png"],
      ["Nick", "nick.png"],
      ["Ninja", "ninja.png"],
      ["Nun", "nun.png"],
      ["Nurse", "nurse.png"],
      ["Officer", "officer.png"],
      ["Priest", "priest.png"],
      ["Queen", "queen.png"],
      ["Robot", "robot.png"],
      ["Robotess", "robotess.png"],
      ["Sportsman", "sportsman.png"],
      ["Teacher", "teacher.png"]
    ]
  );

  public defaultBackgrounds = new Map(
    [
      ["02.jpg", "Alien vs. Predator"],
      ["03.jpg", "Splashy colors with butterflies"],
      ["00.jpg", "Black wooden abstract"],
      ["14.jpg", "Black cubes with red lava"],
      ["15.jpg", "Black ink"],
      ["16.jpg", "Dark polygons on lava"],
      ["17.jpg", "Checkers"],
      ["18.jpg", "Waterfall"],
      ["19.jpg", "Tao flowers"],
      ["22.jpg", "Abstract polygons (blue)"],
      ["23.jpg", "Abstract polygons (pink)"],
      ["24.jpg", "Abstract polygons (grey)"]
    ]
  );

  public comicLibraryBackgrounds = new Map(
    [
      ["00.jpg", "Black wooden abstract"],
      ["01.jpg", "Yellow Watchmen Smile n.1"],
      ["04.jpg", "Yellow Watchmen Smile n.2"],
      ["05.jpg", "Locke & Key"],
      ["06.jpg", "Wolverine"],
      ["07.jpg", "Yellow Watchmen Smile n.3"]
    ]
  );

  public musicLibraryBackgrounds = new Map(
    [
      ["00.jpg", "Black wooden abstract"],
      ["08.jpg", "Red vintage cassette"],
      ["09.jpg", "Red vintage turntable"],
      ["10.jpg", "Black and white guitar"],
      ["11.jpg", "Black and white headphones"],
      ["12.jpg", "Grunge radio"],
      ["13.jpg", "Old score"]
    ]
  );

  public showLibraryBackgrounds = new Map(
    [
      ["00.jpg", "Black wooden abstract"],
      ["20.jpg", "Noisy TV"],
      ["21.jpg", "Detuned TV"],
      ["25.jpg", "Fallout"],
      ["26.jpg", "Orwell"],
      ["27.jpg", "Skyscraper"],
      ["28.jpg", "Hangar"],
      ["29.jpg", "Old TV"],
      ["30.jpg", "Family"],
      ["31.jpg", "Rainbow"],
      ["32.jpg", "Field"]
    ]
  );

  public movieLibraryBackgrounds = new Map(
    [
      ["00.jpg", "Black wooden abstract"],
      ["33.jpg", "Blue reel"],
      ["34.jpg", "Cinema hall"],
      ["35.jpg", "Ciak"],
      ["36.jpg", "Film on reels"],
      ["37.jpg", "Snatch"],
      ["38.jpg", "More reels"]
    ]
  );

  public bookLibraryBackgrounds = new Map(
    [
      ["00.jpg", "Black wooden abstract"],
      ["39.jpg", "Library"],
      ["40.jpg", "Book pile"],
      ["41.jpg", "Blowballs"],
      ["42.jpg", "Paper"],
      ["43.jpg", "Landscape"]
    ]
  );

  public devicePushMethods = new Map(
    [
      ["FTP", "FTP"],
      ["FILECOPY", "File copy"]
    ]
  );

  public newDevice: Device = {
    pushSettings: {} as PushSettings
  } as Device;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    private _notificationService: NotificationService,
  ) {
  }

  onUpdateProperty(propertyName: string, newValue: string) {
    this.profileSettingsService.updateProperty(propertyName, newValue).subscribe((r: boolean) => {
      if (r) {
        this._notificationService.notifySuccess("messages.done");
      }
    });
  }

  /* API Keys */

  onRemoveAPIKeyButtonClick(id: number) {
    this.profileSettingsService.removeAPIKey(id);
  }

  onAddAPIKeyButtonClick() {
    this.profileSettingsService.addAPIKey().subscribe();
  }

  /* Linked identities */

  public newIdentity: LinkedIdentity = {
  } as LinkedIdentity;

  onRemoveLinkedIdentityButtonClick(id: number) {
    this.profileSettingsService.removeLinkedIdentity(id);
  }

  onAddLinkedIdentityButtonClick() {
    this.profileSettingsService.addLinkedIdentity(this.newIdentity);
    this.newIdentity = {} as LinkedIdentity;
  }

  /* Devices */

  onRemoveDeviceButtonClick(id: number) {
    this.profileSettingsService.removeDevice(id);
  }

  onEditDeviceButtonClick(id: number) {
    this.newDevice = this.profileSettingsService.profile.devices.find(o=>o.id == id);
  }

  onUpdateDeviceButtonClick(){
    this.profileSettingsService.updateDevice(this.newDevice);
    this.newDevice = {
      pushSettings: {} as PushSettings
    } as Device;
  }

  onCancelUpdateDeviceButtonClick(){
    this.newDevice = {
      pushSettings: {} as PushSettings
    } as Device;
  }

  onAddDeviceButtonClick() {
    this.profileSettingsService.addDevice(this.newDevice);
    this.newDevice = {
      pushSettings: {} as PushSettings
    } as Device;
  }

  onFavouriteMusicDestinationChange(device: Device) {
    this.profileSettingsService.setDeviceFavouriteMusicDestination(device.id, device.favouriteMusicDestination);
  }

}
