import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard.service';
import { BookLibraryComponent } from './pages/book-library/book-library.component';
import { BookViewerComponent } from './pages/book-viewer/book-viewer.component';
import { IssuesComponent } from './pages/comic-library/issues/issues.component';
import { SeriesComponent } from './pages/comic-library/series/series.component';
import { ComicViewerComponent } from './pages/comic-viewer/comic-viewer.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { EmbeddedAppsComponent } from './pages/embedded-apps/embedded-apps.component';
import { FilemanagerComponent } from './pages/filemanager/filemanager.component';
import { HangfireComponent } from './pages/hangfire/hangfire.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MovieLibraryComponent } from './pages/movie-library/movie-library.component';
import { MusicLibraryComponent } from './pages/music-library/music-library.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { ProposedDownloadsComponent } from './pages/proposed-downloads/proposed-downloads.component';
import { ReceivedMessagesComponent } from './pages/received-messages/received-messages.component';
import { EpisodesComponent } from './pages/show-library/episodes/episodes.component';
import { ShowsComponent } from './pages/show-library/shows/shows.component';
import { StatusComponent } from './pages/status/status.component';

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "book-library",
    component: BookLibraryComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: "books/:id",
    component: BookViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "books/:id/pages/:pageNumber",
    component: BookViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "movie-library",
    component: MovieLibraryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "music-library",
    component: MusicLibraryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "comic-library",
    component: SeriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "comic-library/series/:id",
    component: IssuesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "comics/:seriesId/:id",
    component: ComicViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "comics/:seriesId/:id/pages/:pageNumber",
    component: ComicViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "show-library",
    component: ShowsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "show-library/shows/:id",
    component: EpisodesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "proposed-downloads",
    component: ProposedDownloadsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "scheduled-tasks",
    component: HangfireComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "profile-settings",
    component: ProfileSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "configuration",
    component: ConfigurationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "profiles",
    component: ProfilesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "filemanager",
    component: FilemanagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "received-messages",
    component: ReceivedMessagesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "status",
    component: StatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "embedded-apps",
    component: EmbeddedAppsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
