// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  color: #fff;
  background-color: #333;
}

.audio-tag-wrapper .title {
  font-size: 1.5em;
}
.audio-tag-wrapper audio {
  width: 100%;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/bits/music-player/music-player.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,sBAAA;AACF;;AAGE;EACE,gBAAA;AAAJ;AAGE;EACE,WAAA;EACA,cAAA;AADJ","sourcesContent":[":host {\r\n  color: #fff;\r\n  background-color: #333;\r\n}\r\n\r\n.audio-tag-wrapper {\r\n  .title {\r\n    font-size: 1.5em;\r\n  }\r\n\r\n  audio {\r\n    width: 100%;\r\n    display: block;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
