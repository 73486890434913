import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { GlobalStateService } from './global-state.service';
import { FileSystemObject, FileManagerClient, LongRunningOperation } from '../api.client';
import { interval, Observable, Subscription } from 'rxjs';
import { LongRunningOperationModel } from '../models/filemanager-running-operation.model';

@Injectable()
export class FileManagerStateService implements OnInit, OnDestroy {

  private _pollingSubscription: Subscription;

  public get searchText(): string {
    return this._globalStateService.getLocalValue<string>("FILEMANAGER:SEARCH_TEXT", "");
  };

  public set searchText(v: string) {
    this._globalStateService.setLocalValue<string>("FILEMANAGER:SEARCH_TEXT", v);
  };

  private _panes: FileSystemObject[] = [];

  public get panes(): FileSystemObject[] {
    return this._panes;
  }

  public addPane(value: FileSystemObject) {
    this._panes.push(value);
    this._globalStateService.setLocalValue<FileSystemObject[]>("FILEMANAGER:PANES", this._panes);
  }

  public removePane(index: number) {
    this._panes.splice(index, 1);
    this._globalStateService.setLocalValue<FileSystemObject[]>("FILEMANAGER:PANES", this._panes);
  }

  public removePanes(index: number, count: number) {
    this._panes.splice(index, count);
    this._globalStateService.setLocalValue<FileSystemObject[]>("FILEMANAGER:PANES", this._panes);
  }

  public setPane(index: number, value: FileSystemObject) {
    this._panes[index] =value;
    this._globalStateService.setLocalValue<FileSystemObject[]>("FILEMANAGER:PANES", this._panes);
  }

  public get clipboardItem(): FileSystemObject {
    return this._globalStateService.getLocalValue<FileSystemObject>("FILEMANAGER:CLIPBOARD_ITEM", {});
  };

  public set clipboardItem(v: FileSystemObject) {
    this._globalStateService.setLocalValue<FileSystemObject>("FILEMANAGER:CLIPBOARD_ITEM", v);
  };

  public get clipboardMode(): string {
    return this._globalStateService.getLocalValue<string>("FILEMANAGER:CLIPBOARD_MODE", "");
  };

  public set clipboardMode(v: string) {
    this._globalStateService.setLocalValue<string>("FILEMANAGER:CLIPBOARD_MODE", v);
  };

  private _runningOperations: LongRunningOperation[] = [];

  public get runningOperations(): LongRunningOperation[] {
    return this._runningOperations;
  }

  public addRunningOperation(value: string) {
    this._runningOperations.push({jobId:value});
    this._globalStateService.setLocalValue<LongRunningOperation[]>("FILEMANAGER:RUNNINGOPERATIONS", this._runningOperations);
  }

  constructor(
    private _globalStateService: GlobalStateService,
    private _apiClient: FileManagerClient) {

    this._runningOperations = this._globalStateService.getLocalValue<LongRunningOperation[]>("FILEMANAGER:RUNNINGOPERATIONS", []);
    this._panes = this._globalStateService.getLocalValue<FileSystemObject[]>("FILEMANAGER:PANES",[]);

    let pollingObservable = interval(5000);
    this._pollingSubscription = pollingObservable.subscribe((r: number) => {
      this.poll();
    });

  }

  ngOnInit(): void {
    this.poll();
  }

  ngOnDestroy() {
    this._pollingSubscription.unsubscribe();
  }

  private poll() {
    if (this._runningOperations.length) {
      this._apiClient.getRunningOperations({jobIds:this._runningOperations.map((value) => value.jobId)}).subscribe((r: LongRunningOperation[]) => {
        this._runningOperations = r;
        this._globalStateService.setLocalValue<LongRunningOperation[]>("FILEMANAGER:RUNNINGOPERATIONS", this._runningOperations);
      });
    }
  }

}

