import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalStateService } from '../services/global-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

  constructor(
    private _globalStateService: GlobalStateService
  ) { }

  router: Router;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this._globalStateService.sessionKey) {
      request = request.clone({
        setHeaders: {
          "X-Alessandria-SessionKey": this._globalStateService.sessionKey
        },
        withCredentials: false
      });
    }

    return next.handle(request);

  }

}
