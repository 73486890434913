import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { ConfigurationService } from '../../services/configuration-service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  public editorOptions = { theme: 'vs-dark', language: 'handlebars', automaticLayout: true };
  public newEmbeddedApp: { name: string, url: string } = { name: null, url: null };

  public streamAnalysisModes: Map<string, string> = new Map(
    [
      ["metadata", "Metadata"],
      ["full", "Full"]
    ]
  );

  public staticStreamConversionModes: Map<string, string> = new Map(
    [
      ["inplace", "In place"],
      ["sidebyside", "Side by side"],
      ["none", "None"]
    ]
  );

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public configurationService: ConfigurationService,
    private _notificationService: NotificationService) { }

  async ngOnInit() {

    await this.configurationService.load().toPromise();

  }

  async onSaveButtonClick() {
    await this.configurationService.save();
    this._notificationService.notifySuccess("messages.done");
  }

  async onCancelButtonClick() {
    await this.configurationService.load().toPromise();
    this._notificationService.notifySuccess("messages.done");
  }

  public async onSaveEmbeddedAppButtonClick() {
    this.configurationService.configuration.integrations.embeddedApps[this.newEmbeddedApp.url] = this.newEmbeddedApp.name;
    this.newEmbeddedApp = { name: null, url: null };
  }

  public async onRemoveEmbeddedAppButtonClick(key: string) {
    delete this.configurationService.configuration.integrations.embeddedApps[key];
  }

  public async onEditEmbeddedAppButtonClick(key: string) {
    this.newEmbeddedApp = { url: key, name: this.configurationService.configuration.integrations.embeddedApps[key] };
    delete this.configurationService.configuration.integrations.embeddedApps[key];
  }

}
