import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LibraryExplorerLayoutComponent } from '../../../layouts/library-explorer-layout/library-explorer-layout.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ShowLibraryClient, EpisodePageable, Show } from 'src/app/api.client';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ShowLibraryStateService } from 'src/app/services/library-state/show-library-state.service';
import { CurrentProfileSettingsService } from '../../../services/current-profile-settings.service';

@Component({
  selector: 'app-episodes',
  templateUrl: './episodes.component.html',
  styleUrls: ['./episodes.component.scss']
})
export class EpisodesComponent implements OnInit {

  public showId: number;
  public show: Show;

  @ViewChild(LibraryExplorerLayoutComponent) _libraryExplorerLayout: LibraryExplorerLayoutComponent;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public apiClient: ShowLibraryClient,
    public dialog: MatDialog,
    public screenDetectionService: ScreenDetectionService,
    private route: ActivatedRoute,
    public stateService: ShowLibraryStateService,
    public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {

      this.showId = +params['id'];

      this.apiClient.containerItemDetails({containerItemId:this.showId}).subscribe((r: Show) => {
        this.show = r;
      });

      if (this.showId !== this.stateService.parentId || this.stateService.contentItems.length === 0) {
        this.stateService.parentId = this.showId;
        this.stateService.contentItemsList(true);
      }
  
    });
  }

  public onScrolledToBottom() {
    this.stateService.contentItemsList();
  }

  public onSearchTextChanged(searchText: string) {
    this.stateService.contentItemsSearchText = searchText;
    this.stateService.contentItemsList(true);
    this._libraryExplorerLayout.resetScrollPosition();
  }

  public onViewSliderChange(event: MatSlideToggleChange) {
    this.stateService.contentItemsChangeSelectedView(event.source.name, event.checked);
  }

  public onMarkAsButtonClick(id: number, completed: boolean) {
    this.stateService.contentItemMarkAs(id, completed);
  }

}
