import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LibraryExplorerLayoutComponent } from '../../../layouts/library-explorer-layout/library-explorer-layout.component';
import { ConfirmDialogComponent } from 'src/app/bits/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ComicLibraryClient, Issue, IssuePageable, Series } from 'src/app/api.client';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ComicLibraryStateService } from 'src/app/services/library-state/comic-library-state.service';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { UpdatedValueModel } from '../../../layouts/item-layout/item-layout.component';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {

  @ViewChild(LibraryExplorerLayoutComponent) _libraryExplorerLayout: LibraryExplorerLayoutComponent;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public apiClient: ComicLibraryClient,
    public dialog: MatDialog,
    public screenDetectionService: ScreenDetectionService,
    public route: ActivatedRoute,
    public stateService: ComicLibraryStateService,
    public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {

      const seriesId = +params['id'];

      if (seriesId !== this.stateService.parentId || this.stateService.contentItems.length === 0) {
        this.stateService.containerItemDetails(seriesId).subscribe(o => {
          this.stateService.parent = o;
          this.stateService.parentId = seriesId;
          this.stateService.contentItemsList(true);
        });
      }

    });
  }

  public onScrolledToBottom() {
    this.stateService.contentItemsList();
  }

  public onSearchTextChanged(searchText: string) {
    this.stateService.contentItemsSearchText = searchText;
    this.stateService.contentItemsList(true);
    this._libraryExplorerLayout.resetScrollPosition();
  }

  public onViewSliderChange(event: MatSlideToggleChange) {
    this.stateService.contentItemsChangeSelectedView(event.source.name, event.checked);
  }

  public onMarkAsButtonClick(id: number, completed: boolean) {
    this.stateService.contentItemMarkAs(id, completed);
  }

  public onForceInspectButtonClick(id: number) {
    this.stateService.contentItemForceDeepScan(id);
  }

  public onDisplayModeChanged(mode: string) {
    this.stateService.currentDisplayMode = mode;
  }

  public onRemoveButtonClick(id: number) {
    this.stateService.contentItemRemove(id);
  }

  public onReorgIssueButtonClick(id: number) {
    this.stateService.reorgIssue(id);
  }

  onEditableUpdated(id: number, updatedValueModel: UpdatedValueModel) {
    this.stateService.contentItemUpdateProperty(id, updatedValueModel);
  }

}
