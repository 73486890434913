
import { Component, OnInit, Injector } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiKey } from '../../api.client';
import { sha256 } from 'js-sha256';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { ConfigurationService } from 'src/app/services/configuration-service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public trustDevice: boolean;
  public username: string;
  public password: string;
  public showPassword: boolean;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _injector: Injector,
    private _configurationService: ConfigurationService,
    private _route: ActivatedRoute,
    public globalStateService: GlobalStateService,
  ) {

  }

  ngOnInit() {
  //  if (this.globalStateService.serverUrl && this.globalStateService.apiKeys[this.globalStateService.serverUrl]) {
  //    this.tryApiKeyLogin();
  //  }
  }

  //public tryApiKeyLogin() {

  //  const url = this.globalStateService.serverUrl + `/api/login/apikey?apiKey=${escape(this.globalStateService.apiKeys[this.globalStateService.serverUrl].value)}`;

  //  this._http.post(url, {}, { responseType: 'text' })
  //    .subscribe(
  //      (v: string) => {

  //        this.globalStateService.sessionKey = v;
  //        const currentProfileSettingsService = this._injector.get(CurrentProfileSettingsService);
  //        currentProfileSettingsService.load().subscribe(() => {
  //          this._configurationService.load().subscribe(() => {
  //            this._router.navigateByUrl('/');
  //          });
  //        });

  //      },
  //      (e) => {
  //        if (e.status === 404) {
  //          this.globalStateService.setApiKey(null, null);
  //          this._snackBar.open(`${e.statusText}`, "OK", { duration: 2000 });
  //        } else {
  //          console.log(e);
  //          this._snackBar.open(`An error has occurred during authentication.`, "OK", { duration: 2000 });
  //        }
  //      }
  //    );

  //}

  public tryLocalLogin() {

    const url = this.globalStateService.serverUrl + `/api/login/local?userName=${sha256.hex(this.username)}&password=${sha256.hex(this.password)}&trustDevice=${this.trustDevice}`;

    this._http.post(url, {}, { responseType: 'text' })
      .subscribe(
        (v: string) => {

          this.globalStateService.sessionKey = v;
          this.globalStateService.addServerUrl(this.globalStateService.serverUrl);
          const currentProfileSettingsService = this._injector.get(CurrentProfileSettingsService);
          //const browser = Bowser.getParser(window.navigator.userAgent);

          forkJoin({
            profileSettings: currentProfileSettingsService.load(),
            configuration: this._configurationService.load(),
            params: this._route.queryParams.pipe(take(1))
          }).subscribe(o => {
            if (o.params["r"]) {
              this._router.navigateByUrl(decodeURI(o.params["r"]));
            } else {
              this._router.navigateByUrl(`/`);
            }
          })

        },
        (e) => {
          this._snackBar.open(`An error has occurred during authentication. Please try again.`, "OK", { duration: 2000 });
        }
      );

  }

  public onServerUrlAutocompleteOptionSelected(event: MatAutocompleteSelectedEvent) {

    this.globalStateService.serverUrl = event.option.value;

  }

}
