import { Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { ScrollEvent } from '../../directives/scroll-event.directive';

@Component({
  selector: 'app-library-explorer-layout',
  templateUrl: './library-explorer-layout.component.html',
  styleUrls: ['./library-explorer-layout.component.scss']
})
export class LibraryExplorerLayoutComponent {

  @Input() public pageTitle: string;
  @Input() public backgroundImage: string;
  @Input() public searchText: string;
  @Input() public initials: string[];
  @Input() public currentDisplayMode = "card";

  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedViewsChanged: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Output() scrolledToBottom: EventEmitter<void> = new EventEmitter<void>();
  @Output() displayModeChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('drawerContent') drawerContent: MatDrawerContent;

  public showFilters = false;

  constructor(
    public screenDetectionService: ScreenDetectionService) {
  }

  onSearchFormSubmit(event) {
    if (this.searchText === event.target["searchText"].value) {
      return;
    }
    this.searchText = event.target["searchText"].value;
    this.searchTextChanged.emit(this.searchText);
    this.resetScrollPosition();
  }

  onClearSearchTextButtonClick() {
    if (this.searchText === "") {
      return;
    }
    this.searchText = "";
    this.searchTextChanged.emit(this.searchText);
    this.resetScrollPosition();
  }

  onChangeDisplayModeClick() {
    this.currentDisplayMode = this.currentDisplayMode === "card" ? "list" : "card";
    this.displayModeChanged.emit(this.currentDisplayMode);
  }

  onToggleFiltersClick() {
    this.showFilters = !this.showFilters;
  }

  onDrawerScroll(event: ScrollEvent) {

    if (event.isReachingBottom && !event.isWindowEvent) {
        this.scrolledToBottom.emit();
    }

  }

  public resetScrollPosition() {
    this.drawerContent.scrollTo({ top: 0 });
  }

}

