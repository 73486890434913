import { Injectable } from '@angular/core';
import { Hardware } from '../api.client';
import { WsClientService } from './ws-client.service';

@Injectable({
  providedIn: 'root'
})
export class RealTimeDataService {

  public runningOperationsCount: number = 0;
  public messagesCount: number = 0;
  public hardwareStatus: Hardware[] = [];

  constructor(
    private _wsClientService: WsClientService
  ) {

    _wsClientService.messagesCount.subscribe((o) => {
      this.messagesCount = o;
    });

    _wsClientService.runningOperationsCount.subscribe((o) => {
      this.runningOperationsCount = o;
    });

    _wsClientService.hardwareStatus.subscribe((r) => {
      this.hardwareStatus = r;
    });

  }

}
