// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
}

.enabled {
  border-bottom: 1px dashed #808080;
}`, "",{"version":3,"sources":["webpack://./src/app/bits/editable/editable.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,iCAAA;AACF","sourcesContent":[":host {\r\n  display: inline-block;\r\n}\r\n\r\n.enabled{\r\n  border-bottom: 1px dashed #808080;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
