import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {  Configuration, ConfigClient } from '../api.client';
import { GlobalStateService } from './global-state.service';

@Injectable()
export class ConfigurationService {

  private _configuration: Configuration = {} as Configuration;

  public get configuration(): Configuration {
    return this._configuration;
  }
  public profileSubject: BehaviorSubject<Configuration> = new BehaviorSubject(this._configuration);
  public profileChanged: Observable<Configuration> = this.profileSubject.asObservable();

  public loaded = false;

  constructor(
    private _apiClient: ConfigClient,
    private _globalStateService: GlobalStateService
) {
      this.load().subscribe();
  }

  private _loadingObservable: Observable<boolean> = null;

  public load(): Observable<boolean> {

    if (this._loadingObservable === null) {

      this._loadingObservable = Observable.create(observer => {

        if (!this._globalStateService.sessionKey) {
          observer.next(false);
        } else {

          this.loaded = false;
          this._apiClient.getConfiguration().subscribe((r: Configuration) => {
            this._configuration = r;
            this.profileSubject.next(this._configuration)
            this.loaded = true;
            observer.next(true);
          }, () => {
            observer.next(false);
          }, () => {
            observer.complete();
          });
        }

      });

    }

    return this._loadingObservable;

  }

  public async save() {
    await this._apiClient.putConfiguration({body:this._configuration}).toPromise();
  }

}
