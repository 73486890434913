import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingDetectionService {

  private static _requestsCount: number = 0;

  public isLoading: boolean = false;

  public requestStarted() {
    LoadingDetectionService._requestsCount++;
    this.isLoading = LoadingDetectionService._requestsCount > 0;
  }

  public requestEnded() {
    LoadingDetectionService._requestsCount--;
    this.isLoading = LoadingDetectionService._requestsCount > 0;
  }

  constructor() {
  }

}
