import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentProfileClient, Profile, ApiKey, LinkedIdentity, Device } from '../api.client';
import { sha256 } from 'js-sha256';

import * as generator from 'generate-password-browser';

@Injectable()
export class CurrentProfileSettingsService {

  private _profile: Profile = {
    id: 0,
    key: "",
    skin: "red-light-theme",
    defaultBackgroundImage: "00.jpg",
    comicLibraryBackgroundImage: "00.jpg",
    musicLibraryBackgroundImage: "00.jpg",
    showLibraryBackgroundImage: "00.jpg",
    movieLibraryBackgroundImage: "00.jpg",
    bookLibraryBackgroundImage: "00.jpg"
  };

  public get profile(): Profile {
    return this._profile;
  }
  public set profile(v: Profile) {
    this._profile = v;
    this.profileSubject.next(this._profile)
  }
  public profileSubject: BehaviorSubject<Profile> = new BehaviorSubject(this._profile);
  public profileChanged: Observable<Profile> = this.profileSubject.asObservable();

  public loaded = false;

  public get skin(): string {
    return this._profile.skin;
  }

  public get isLightSkin(): boolean {
    return this._profile.skin.indexOf("light") > -1;
  }

  public get avatar(): string {
    return this._profile.avatar;
  }

  public get displayName(): string {
    return this._profile.displayName;
  }

  public get defaultBackgroundImage(): string {
    return this._profile.defaultBackgroundImage;
  }

  public get comicLibraryBackgroundImage(): string {
    return this._profile.comicLibraryBackgroundImage;
  }

  public get musicLibraryBackgroundImage(): string {
    return this._profile.musicLibraryBackgroundImage;
  }

  public get showLibraryBackgroundImage(): string {
    return this._profile.showLibraryBackgroundImage;
  }

  public get movieLibraryBackgroundImage(): string {
    return this._profile.movieLibraryBackgroundImage;
  }

  public get bookLibraryBackgroundImage(): string {
    return this._profile.bookLibraryBackgroundImage;
  }

  constructor(
    public apiClient: CurrentProfileClient) {

      this.load().subscribe();

  }

  private _loadingObservable: Observable<boolean> = null;

  public load(): Observable<boolean> {

    if (this._loadingObservable === null) {

      this._loadingObservable = Observable.create(observer => {

        this.loaded = false;
        this.apiClient.single().subscribe((r: Profile) => {
          this._profile = r;
          this.profileSubject.next(this._profile)
          this.loaded = true;
          observer.next(true);
        }, () => {
          observer.next(false);
        }, () => {
          observer.complete();
        });

      });

    }

    return this._loadingObservable;

  }

  public updateProperty(propertyName: string, newValue: string): Observable<boolean> {

    return Observable.create(observer => {
      this.apiClient.updateProperty({fieldName:propertyName,body: newValue}).subscribe(() => {
        this.apiClient.single().subscribe((r: Profile) => {
          this.profile = r;
        }, () => {
          observer.next(null);
        }, () => {
          observer.complete();
        })
      }, () => {
        observer.next(false);
      }, () => {
        observer.complete();
      })
    });

  }

  public addAPIKey(displayName = ""): Observable<ApiKey> {

    const key = generator.generate({
      length: 50,
      numbers: true,
      uppercase: true
    })

    const apiKey: ApiKey = {
      id: 0,
      displayName: displayName ? displayName : key,
      key: key
    };

    return Observable.create(observer => {

      this.apiClient.addApiKey({body:apiKey}).subscribe(() => {
        this.apiClient.single().subscribe((r: Profile) => {
          this._profile.apiKeys = r.apiKeys;
          observer.next(apiKey);
        }, () => {
          observer.next(null);
        }, () => {
          observer.complete();
        })
      });

    });

  }

  public removeAPIKey(id: number) {
    this.apiClient.deleteApiKey({apiKeyId:id}).subscribe(() => {
      this.apiClient.single().subscribe((r: Profile) => {
        this._profile.apiKeys = r.apiKeys;
      });
    });
  }

  public addLinkedIdentity(linkedIdentity: LinkedIdentity) {
    linkedIdentity.id = 0;
    linkedIdentity.key = sha256.hex(linkedIdentity.key);
    linkedIdentity.password = sha256.hex(linkedIdentity.password);
    this.apiClient.addLinkedIdentity({body:linkedIdentity}).subscribe(() => {
      this.apiClient.single().subscribe((r: Profile) => {
        this._profile.linkedIdentities = r.linkedIdentities;
      });
    });
  }

  public removeLinkedIdentity(id: number) {
    this.apiClient.deleteLinkedIdentity({linkedIdentityId:id}).subscribe(() => {
      this.apiClient.single().subscribe((r: Profile) => {
        this._profile.linkedIdentities = r.linkedIdentities;
      });
    });
  }

  public addDevice(newDevice: Device) {
    this.apiClient.addDevice({body:newDevice}).subscribe(() => {
      this.apiClient.single().subscribe((r: Profile) => {
        this._profile.devices = r.devices;
      });
    });
  }

  public updateDevice(newDevice: Device) {
    this.apiClient.updateDevice({body:newDevice}).subscribe(() => {
      this.apiClient.single().subscribe((r: Profile) => {
        this._profile.devices = r.devices;
      });
    });
  }

  public removeDevice(id: number) {
    this.apiClient.deleteDevice({deviceId:id}).subscribe(() => {
      this.apiClient.single().subscribe((r: Profile) => {
        this._profile.devices = r.devices;
      });
    });
  }

  public setDeviceFavouriteMusicDestination(id: number, favouriteMusicDestination: boolean) {
    this.apiClient.setDeviceFavouriteMusicDestination({deviceId:id, favouriteMusicDestination:favouriteMusicDestination}).subscribe(() => {
      this.apiClient.single().subscribe((r: Profile) => {
        this._profile.devices = r.devices;
      });
    });
  }

}
