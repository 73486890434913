import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { GlobalStateService } from '../../services/global-state.service';
import { MusicPlayerStateService } from '../../services/music-player-state.service';
import { MusicPlayerStateModel, MusicPlayerStatus } from '../../models/music-player-state.model';
import { Track } from '../../api.client';

@Component({
  selector: 'app-music-player',
  templateUrl: './music-player.component.html',
  styleUrls: ['./music-player.component.scss']
})
export class MusicPlayerComponent implements OnInit, AfterViewInit {

  @ViewChild("audioTag") audioTag: ElementRef;

  public currentTrackStreamUrl: string;
  public currentTrack: Track = null;

  constructor(
    public globalStateService: GlobalStateService,
    public musicPlayerStateService: MusicPlayerStateService) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.bindAudioTagEvents();

    this.musicPlayerStateService.stateChanged.subscribe((stateModel: MusicPlayerStateModel) => {

      if (stateModel.currentTrack) {
        this.currentTrackStreamUrl = this.globalStateService.serverUrl + "/" + stateModel.currentTrack.streamUrl.replace("[sessionCacheKey]", this.globalStateService.sessionKey) + "?ngsw-bypass";
        this.currentTrack = stateModel.currentTrack;
      } else {
        this.currentTrack = null;
      }

      switch (stateModel.status) {
        case MusicPlayerStatus.paused:
        case MusicPlayerStatus.stopped:
          this.pause();
          break;
        case MusicPlayerStatus.playing:
          this.play();
          break;
      }

    });

  }

  bindAudioTagEvents() {

    this.audioTag.nativeElement.volume = this.globalStateService.musicPlayerVolume;

    this.audioTag.nativeElement.addEventListener("play", () => {
      this.musicPlayerStateService.changeStatus(MusicPlayerStatus.playing);
    }, false);

    this.audioTag.nativeElement.addEventListener("pause", () => {
      this.musicPlayerStateService.changeStatus(MusicPlayerStatus.paused);
    }, false);

    this.audioTag.nativeElement.addEventListener("ended", () => {
      this.musicPlayerStateService.next();
    }, false);

    this.audioTag.nativeElement.addEventListener("loadstart", () => {

    }, false);

    this.audioTag.nativeElement.addEventListener("loadeddata", () => {

    }, false);

    this.audioTag.nativeElement.addEventListener("loadedmetadata", () => {

    }, false);

    this.audioTag.nativeElement.addEventListener("volumechange", (evt) => {
      this.globalStateService.musicPlayerVolume = this.audioTag.nativeElement.volume;
    }, false);

  }

  public play() {
    if (this.audioTag.nativeElement.src.indexOf(this.currentTrackStreamUrl) == -1) {
      this.audioTag.nativeElement.src = this.currentTrackStreamUrl;
    }
    this.audioTag.nativeElement.play();
  }

  public pause() {
    this.audioTag.nativeElement.pause();
  }

}
