import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'parseJson'})
export class ParseJsonPipe implements PipeTransform  {
  constructor() {}
  transform(value:string):object {
    return JSON.parse(value);
  }
}
