import { Component, OnInit, ViewChild } from '@angular/core';
import { ComicLibraryClient, Issue, Series, IssuePageable } from 'src/app/api.client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalStateService } from '../../services/global-state.service';
import { PinchZoomComponent } from '@meddv/ngx-pinch-zoom';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComicLibraryStateService } from '../../services/library-state/comic-library-state.service';
import { delay } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-comic-viewer',
  templateUrl: './comic-viewer.component.html',
  styleUrls: ['./comic-viewer.component.scss']
})
export class ComicViewerComponent implements OnInit {

  public seriesId: number;
  public series: Series;

  public issue: Issue;
  public issueId: number;
  public issueLength: number;
  public issuePageNumbers: Array<number>;
  public issuePageUrls: Array<string>;

  public pageNumber?: number;
  public currentPageImageUrl: string;
  public previousPageImageUrl1: string;
  public nextPageImageUrl1: string;
  public nextPageImageUrl2: string;

  @ViewChild('pinchZoom') pinchZoom: PinchZoomComponent;

  constructor(
    private _comicLibraryClient: ComicLibraryClient,
    private _route: ActivatedRoute,
    public globalStateService: GlobalStateService,
    private _notificationService: NotificationService,
    private _router: Router,
    private _stateService: ComicLibraryStateService) { }

  ngOnInit() {
    this._route.params.subscribe((params: Params) => {

      this.seriesId = +params['seriesId'];
      this.issueId = +params['id'];
      this.pageNumber = params['pageNumber'] ? +params['pageNumber'] : null;

      this._stateService.parentId = this.seriesId;

      this._comicLibraryClient.contentItemDetails({contentItemId:this.issueId}).subscribe((issue: Issue) => {

        this.pageNumber = (this.pageNumber === null ? (issue.progress === 0 ? 1 : issue.progress) : this.pageNumber);

        this.issuePageNumbers = Array.from({ length: issue.length }, (x, i) => i + 1);

        this.issue = issue;
        this.issueLength = issue.length;

        this.loadPage();

      });

      this._comicLibraryClient.containerItemDetails({containerItemId:this.seriesId}).subscribe((series:Series) => {
        this.series = series;
      });

    });
  }

  public getPageThumbnailUrl(pageNumber: number) {
    return this.globalStateService.serverUrl + "/" + this.issue.pagesThumbnailsUrls[pageNumber - 1].replace("[sessionCacheKey]", this.globalStateService.sessionKey)
  }

  onJumpToPageButtonClick(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.loadPage();
  }

  private loadPage() {

    if (this.currentPageImageUrl) {
      this.previousPageImageUrl1 = this.currentPageImageUrl
    }

    this.currentPageImageUrl = this.globalStateService.serverUrl + "/" + this.issue.pagesUrls[this.pageNumber - 1].replace("[sessionCacheKey]", this.globalStateService.sessionKey);
    this._comicLibraryClient.contentItemUpdateReadPages({contentItemId:this.issueId, readPages: this.pageNumber}).subscribe();
    this._stateService.contentItemUpdate(this.issueId);
    this._stateService.containerItemUpdate(this.seriesId);

    setTimeout(() => {
      if (this.pageNumber < this.issue.pagesUrls.length) {
        this.nextPageImageUrl1 = this.globalStateService.serverUrl + "/" + this.issue.pagesUrls[this.pageNumber].replace("[sessionCacheKey]", this.globalStateService.sessionKey);
      }
    }, 500);

    setTimeout(() => {
      if (this.pageNumber + 1 < this.issue.pagesUrls.length) {
        this.nextPageImageUrl2 = this.globalStateService.serverUrl + "/" + this.issue.pagesUrls[this.pageNumber + 1].replace("[sessionCacheKey]", this.globalStateService.sessionKey);
      }
    }, 1000);

  }

  onJumpToNextIssueButtonClick() {

    this._comicLibraryClient.contentItemsList({
      pageNumber:0,
      pageSize:1,
      filterView:'not-read',
      containerItemId: this.seriesId
    }).subscribe((r: IssuePageable) => {
      if (r.currentPage.length === 0) {
        this._notificationService.notifyError("messages.lastIssueInSeries").afterDismissed().subscribe(() => {
          this._router.navigateByUrl("/comic-library");
        });
      } else {
        this._router.navigate(['/comics/', this.seriesId, r.currentPage[0].id])
      }
    });

  }

  public onRemoveSeriesButtonClick() {
    this._stateService.containerItemRemove(this.series).subscribe(() => { this._router.navigateByUrl("/comic-library") });
  }

}

