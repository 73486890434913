// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item circle-progress {
  position: absolute;
  top: 0;
  left: 0;
}

.item-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: block;
  height: 400px;
  position: relative;
}

.item-preview span {
  display: block;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  text-align: center;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
}

.actions {
  display: flex;
  justify-content: space-between;
}
.actions .action {
  opacity: 0.8;
  font-size: 4vh;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/library-stats-layout/library-stats-layout.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;AAAJ;;AAIA;EACE,sBAAA;EACA,4BAAA;EACA,+BAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;AADF;;AAIA;EACE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,8BAAA;EACA,WAAA;AADF;;AAIA;EACE,aAAA;EACA,8BAAA;AADF;AAGE;EACE,YAAA;EACA,cAAA;AADJ","sourcesContent":[".item {\r\n  circle-progress {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n  }\r\n}\r\n\r\n.item-preview {\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position: top center;\r\n  display: block;\r\n  height: 400px;\r\n  position: relative;\r\n}\r\n\r\n.item-preview span {\r\n  display: block;\r\n  position: absolute;\r\n  bottom: 0px;\r\n  padding: 10px;\r\n  text-align: center;\r\n  left: 0px;\r\n  right: 0px;\r\n  background: rgba(0, 0, 0, 0.50);\r\n  color: #FFF;\r\n}\r\n\r\n.actions {\r\n  display: flex;\r\n  justify-content: space-between;\r\n\r\n  .action {\r\n    opacity: .80;\r\n    font-size: 4vh;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
