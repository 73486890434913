// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  position: fixed;
  bottom: 0px;
  z-index: 101;
  width: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF","sourcesContent":[".progress {\r\n  position: fixed;\r\n  bottom: 0px;\r\n  z-index: 101;\r\n  width: 100%;\r\n}\r\n\r\n.layout {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
