import { Component, OnInit,  Input, HostBinding} from '@angular/core';
import { MusicPlayerStateService } from 'src/app/services/music-player-state.service';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { FileManagerStateService } from 'src/app/services/file-manager-state.service';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { RealTimeDataService } from 'src/app/services/real-time-data-service';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { Profile } from 'src/app/api.client';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit  {

  @Input() public backgroundImage: string;
  @Input() public exitRoute: string;

  @HostBinding('class') componentCssClass;
  public openDrawer = false;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public musicPlayerStateService: MusicPlayerStateService,
    private _router: Router,
    public screenDetectionService: ScreenDetectionService,
    public fileManagerStateService: FileManagerStateService,
    public globalStateService: GlobalStateService,
    public realTimeDataService: RealTimeDataService,
    public overlayContainer: OverlayContainer,
    public snackBar: MatSnackBar,
    public clipboardService: ClipboardService,
    private _notificationService: NotificationService
  ) {

      this.profileSettingsService.profileChanged.subscribe((profile: Profile) => {
        this.overlayContainer.getContainerElement().classList.add(profile.skin);
        this.componentCssClass = profile.skin;
      });
  
  }

  ngOnInit() {
  }

  public async onLogoutClick() {

    this.globalStateService.sessionKey = null;
    this.globalStateService.serverUrl = null;
    this._router.navigateByUrl('/login');

  }

  public onCopyDavUrlButtonClick() {
    this.clipboardService.copyFromContent(`${this.globalStateService.serverUrl}/dav${this.globalStateService.sessionKey}`);
    this._notificationService.notifySuccess("messages.done");
  }


}
