import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from '../../services/global-state.service';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { ConfigurationService } from '../../services/configuration-service';

@Component({
  selector: 'app-embedded-apps',
  templateUrl: './embedded-apps.component.html',
  styleUrls: ['./embedded-apps.component.scss']
})
export class EmbeddedAppsComponent implements OnInit {

  public embeddedApps: { [key: string]: string; };

  constructor(
    public globalStateService: GlobalStateService,
    public profileSettingsService: CurrentProfileSettingsService,
    private _configurationService: ConfigurationService
  ) {
  }

  async ngOnInit() {
    await this._configurationService.load().toPromise();
    this.embeddedApps = this._configurationService.configuration.integrations.embeddedApps;
  }

}
