import { Component, OnInit } from '@angular/core';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { CurrentProfileClient, Message  } from '../../api.client';

@Component({
  selector: 'app-received-messages',
  templateUrl: './received-messages.component.html',
  styleUrls: ['./received-messages.component.scss']
})
export class ReceivedMessagesComponent implements OnInit {

  public messages: Message[] = [];
  public displayedColumns: string[] = ['creation', 'type', 'title', 'content', 'additionalPayload'];

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    private _currentProfileClient: CurrentProfileClient
  ) { }

  ngOnInit() {

    this._currentProfileClient.receivedMessages().subscribe((r: Message[]) => {
      this.messages = r;
    });

  }

  public onDismissAllButtonClick() {
    this._currentProfileClient.dismissAllMessages().subscribe(() => {
      this.messages = [];
    });
  }

}
