import { Component, Input, ElementRef } from '@angular/core';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { BaseItem } from '../../api.client';

@Component({
  selector: 'app-library-stats-layout',
  templateUrl: './library-stats-layout.component.html',
  styleUrls: ['./library-stats-layout.component.scss']
})
export class LibraryStatsLayoutComponent {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() progressPercent: number;
  @Input() recent: BaseItem[];
  @Input() routeBase: string;

  public currentItemIndex = 0;

  constructor(
    public ref: ElementRef,
    public screenDetectionService: ScreenDetectionService,
    public globalStateService: GlobalStateService) { }

  prevItem() {
    this.currentItemIndex--;
  }

  nextItem() {
    this.currentItemIndex++;
  }

}
