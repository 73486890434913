import { Injectable } from '@angular/core';
import { GlobalStateService } from '../global-state.service';
import { Issue, ComicLibraryClient, IssuePageable, Series, ComicVineVolume } from 'src/app/api.client';
import { BaseLibraryStateService } from './base-library-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ScreenDetectionService } from '../screen-detection.service';
import { SearchOnComicVineDialogComponent } from 'src/app/bits/search-on-comicvine-dialog/search-on-comicvine-dialog.component';
import { SearchOnMetadataProviderDialogModel } from 'src/app/models/search-on-metadata-provider.model';
import { NotificationService } from '../notification.service';

@Injectable()
export class ComicLibraryStateService extends BaseLibraryStateService<Series, Issue> {

  public contentItemsViews: string[] = ["not-read", "reading", "potentially-misplaced", "updated-today"];

  public containerItemsViews: string[] =
    [
      "not-read",
      "reading",
      "not-scraped",
      "potentially-misplaced",
      "missing-issues",
      "monitored",
      "not-monitored",
      "updated-today",
      "published"
    ];

  private _comicLibraryClient: ComicLibraryClient;

  constructor(
    globalStateService: GlobalStateService,
    client: ComicLibraryClient,
    _notificationService: NotificationService,
    dialog: MatDialog,
    screenDetectionService: ScreenDetectionService
  ) {
    super(globalStateService, "COMICLIBRARY", _notificationService, dialog, screenDetectionService, client);
    this._comicLibraryClient = client;
  }

  public reorgIssue(id: number) {

    this._comicLibraryClient.contentItemReorg({contentItemId:id}).subscribe(() => {
      this.contentItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    });

  }

  public toggleMonitor(id: number, value: boolean) {
    this._comicLibraryClient.containerItemSetMonitor({containerItemId:id, monitor:value}).subscribe(() => {
      this.containerItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    })
  }

  public toggleIgnoreScraping(id: number, value: boolean) {
    this._comicLibraryClient.containerItemSetIgnoreScraping({containerItemId:id, ignoreScraping:value}).subscribe(() => {
      this.containerItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    })
  }

  public searchComicVine(id: number) {

    const item: Series = this.containerItemGetItemById(id);

    this._dialog.open(SearchOnComicVineDialogComponent, {
      width: this._screenDetectionService.defaultDialogWidth,
      data: new SearchOnMetadataProviderDialogModel(
        item.title,
        "Issues: " + item.length + ", year: " + item.year
      )
    }).afterClosed().subscribe((result: ComicVineVolume) => {
      if (result) {
        this._comicLibraryClient.containerItemSetComicVineId({containerItemId:id, comicVineId:result.id}).subscribe(() => {
          this.containerItemUpdate(id);
          this._notificationService.notifySuccess("messages.done");
        });
      }
    });

  }

}

