import { Injectable } from '@angular/core';
import { GlobalStateService } from './global-state.service';
import { ProposedDownloadsClient, ProposedDownload, ProposedDownloadPageable } from 'src/app/api.client';

@Injectable()
export class ProposedDownloadsStateService {

  public get searchText(): string {
    return this.globalStateService.getLocalValue<string>("PROPOSEDDOWNLOADS:SEARCH_TEXT", "");
  };

  public set searchText(v: string) {
    this.globalStateService.setLocalValue<string>("PROPOSEDDOWNLOADS:SEARCH_TEXT", v);
  };

  public get selectedViews(): string[] {
    return (this.globalStateService.getLocalValue<string[]>("PROPOSEDDOWNLOADS:SELECTED_VIEWS", []));
  };

  public addSelectedView(v: string) {
    let sv = this.selectedViews;
    sv.push(v);
    this.selectedViews = sv;
  }

  public removeSelectedView(v: string) {
    let sv = this.selectedViews;
    sv.splice(this.selectedViews.indexOf(v))
    this.selectedViews = sv;
  }

  public set selectedViews(v: string[]) {
    this.globalStateService.setLocalValue<string[]>("PROPOSEDDOWNLOADS:SELECTED_VIEWS", v);
  };

  public items: Array<ProposedDownload> = [];
  public totalItemsCount: number = 999999;
  public nextPageIndex: number = 0;

  constructor(
    public globalStateService: GlobalStateService,
    public apiClient: ProposedDownloadsClient) {
  }

  public updateSingleItem(id: number) {

    let index: number = this.items.findIndex(o => o.id == id);

    if (index < 0) {
      return;
    }

    this.apiClient.list(
      {
        pageNumber:0,
        pageSize:1,
        filterText: this.searchText,
        filterView: this.selectedViews.join(','),
        id: id
      }
    )
      .subscribe((r: ProposedDownloadPageable) => {
        if (r.currentPage.length == 0) {
          this.items.splice(index, 1);
          this.totalItemsCount--;
        } else {
          this.items[index] = r.currentPage[0];
        }
      });

  }

  public getItemByID(id: number): ProposedDownload {
    return this.items.find(o => o.id == id);
  }

}

