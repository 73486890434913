import { Component, OnInit } from '@angular/core';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { GlobalStateService } from '../../services/global-state.service';
import { ConfigClient, Configuration } from '../../api.client';

@Component({
  selector: 'app-hangfire',
  templateUrl: './hangfire.component.html',
  styleUrls: ['./hangfire.component.scss']
})
export class HangfireComponent implements OnInit {

  public hangfireUrl: string;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public globalStateService: GlobalStateService,
    public configClient: ConfigClient) {
  }

  ngOnInit() {
    this.configClient.getConfiguration().subscribe((r: Configuration) => {
      this.hangfireUrl = this.globalStateService.serverUrl + r.hangFirePath;
    });

  }

}
