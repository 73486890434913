import { Injectable, Injector } from '@angular/core';
import { throwError as observableThrowError, Observable, throwError, OperatorFunction } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStateService } from '../services/global-state.service';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private _globalStateService: GlobalStateService,
    private _router: Router,
    private _notificationService: NotificationService,
    private _route:ActivatedRoute
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const catchErrorFunction: OperatorFunction<HttpEvent<any>, HttpEvent<any>> = catchError((error: HttpErrorResponse) => {

      switch (error.status) {
        case 401:
          this._globalStateService.sessionKey = "";
          if (!location.hash.includes("/login")) {
            this._router.navigateByUrl(`/login?returnUrl=${encodeURIComponent(location.hash.substring(2))}`);
          }
          break;
        default:
          if (error.statusText?.indexOf("SYS_") != 0) {
            if (request.url.indexOf("runningoperations") < 0) {
              this._notificationService.notifyError('messages.serverError', error);
            }
          } else if (error.error instanceof ErrorEvent) {
            this._notificationService.notifyError('messages.unexpectedError', {message: error.message ?? error.error.message});
          } else if (error.error instanceof Blob) {
            error.error.text().then(msg => {
              let err: { message: string, details: string } = { message: "", details: "" }
              try {
                err = JSON.parse(msg);
              } catch {
                err.message = msg;
              }
              this._notificationService.notifyError('messages.unexpectedError', { message: err.message });
            });
          }

      }

      return throwError(error);

    });

    return next.handle(request).pipe(catchErrorFunction);
  }

}
