import { Injectable } from '@angular/core';
import { GlobalStateService } from '../global-state.service';
import { Movie, MovieLibraryClient, MoviePageable, TMDBResult, VideoResolutions } from 'src/app/api.client';
import { BaseLibraryStateService } from './base-library-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ScreenDetectionService } from '../screen-detection.service';
import { SearchOnTMDBDialogComponent } from '../../bits/search-on-tmdb-dialog/search-on-tmdb-dialog.component';
import { SearchOnMetadataProviderDialogModel } from '../../models/search-on-metadata-provider.model';
import { NotificationService } from '../notification.service';

@Injectable()
export class MovieLibraryStateService extends BaseLibraryStateService<never, Movie> {

  public contentItemsViews: string[] = [
      "not-seen",
      "not-scraped",
      "published"
    ];

  private _movieLibraryClient: MovieLibraryClient;

  constructor(
    globalStateService: GlobalStateService,
    client: MovieLibraryClient,
    _notificationService: NotificationService,
    dialog: MatDialog,
    screenDetectionService: ScreenDetectionService
  ) {
    super(globalStateService, "MOVIELIBRARY", _notificationService, dialog, screenDetectionService, client);
    this._movieLibraryClient = client;
  }

  public searchTmdb(id: number) {

    const item: Movie = this.contentItemGetItemById(id);

    this._dialog.open(SearchOnTMDBDialogComponent, {
      width: this._screenDetectionService.defaultDialogWidth,
      data: new SearchOnMetadataProviderDialogModel(
        item.title,
        "Year: " + item.year
      )
    }).afterClosed().subscribe((result: TMDBResult) => {
      if (result) {
        this._movieLibraryClient.contentItemSetTmdbId({contentItemId:id, tmdbId: result.id}).subscribe(() => {
          this.contentItemUpdate(id);
          this._notificationService.notifySuccess("messages.done");
        });
      }
    });

  }

}

