import { Component, OnInit } from '@angular/core';
import { FileManagerClient, FileSystemObject } from '../../api.client';
import { FileManagerStateService } from '../../services/file-manager-state.service';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../bits/confirm-dialog/confirm-dialog.component';
import { ScreenDetectionService } from '../../services/screen-detection.service';
import { ConfirmDialogModel } from '../../models/confirm-dialog.model';
import { GlobalStateService } from '../../services/global-state.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.scss']
})
export class FilemanagerComponent implements OnInit {

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public apiClient: FileManagerClient,
    public stateService: FileManagerStateService,
    private _notificationService: NotificationService,
    public dialog: MatDialog,
    public screenDetectionService: ScreenDetectionService,
    public globalStateService: GlobalStateService) {
  }

  ngOnInit() {
    if (!this.stateService.panes.length) {
      this.apiClient.listChildren({directoryPath:""}).subscribe((r: FileSystemObject) => {
        this.stateService.addPane(r);
      });
      if (!this.screenDetectionService.isMobile) {
        this.apiClient.listChildren({directoryPath:""}).subscribe((r: FileSystemObject) => {
          this.stateService.addPane(r);
        });
      }
    } else {
      if (this.screenDetectionService.isMobile) {
        this.stateService.removePanes(1, 100);
      } else {
        if (this.stateService.panes.length === 1) {
          this.apiClient.listChildren({directoryPath:""}).subscribe((r: FileSystemObject) => {
            this.stateService.addPane(r);
          });
        }
      }
    }
  }

  onFileSystemObjectButtonClick(targetPane: number, item: FileSystemObject) {
    this.followPath(targetPane, item.follow);
  }

  onBreadCrumbButtonClick(targetPane: number, follow: string) {
    this.followPath(targetPane, follow);
  }

  followPath(targetPane: number, follow: string) {
    this.apiClient.listChildren({directoryPath:follow}).subscribe((r: FileSystemObject) => {
      if (r.name) {
        this.stateService.setPane(targetPane, r);
      }
    });
  }

  onCopyButtonClick(item: FileSystemObject) {
    this.stateService.clipboardItem = item;
    this.stateService.clipboardMode = "copy";
  }

  onCutButtonClick(item: FileSystemObject) {
    this.stateService.clipboardItem = item;
    this.stateService.clipboardMode = "cut";
  }

  onDeleteButtonClick(targetPane: number, item: FileSystemObject) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: this.screenDetectionService.defaultDialogWidth,
      data: new ConfirmDialogModel(
        "exclamation-triangle",
        "Do you want to delete \"" + item.name + "\"?",
        "This operation can't be undone.")
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiClient.remove({path:item.follow}).subscribe(() => {
          this.onRefreshPaneButtonClick(targetPane);
          this._notificationService.notifySuccess("messages.done");
        });
      }
    });

  }

  onPasteButtonClick(targetPane: number, confirmOverwrite: boolean = false) {

    switch (this.stateService.clipboardMode) {
      case "copy":
        this.apiClient.copy({originPath:this.stateService.clipboardItem.follow,destinationPath: this.stateService.panes[targetPane].follow,confirmOverwrite: false}).subscribe(
          (r: string) => {
            this.stateService.addRunningOperation(r)
            this.stateService.clipboardItem = {};
            this._notificationService.notifySuccess("messages.done");
          },
          (error: any) => {

            if (error.status == 409) {

              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: this.screenDetectionService.defaultDialogWidth,
                data: new ConfirmDialogModel(
                  "exclamation-triangle",
                  "Do you want to overwrite \"" + this.stateService.clipboardItem.name + "\"?",
                  "This operation can't be undone.")
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.apiClient.copy({originPath:this.stateService.clipboardItem.follow,destinationPath: this.stateService.panes[targetPane].follow,confirmOverwrite: true}).subscribe(
                    (r: string) => {
                      this.stateService.addRunningOperation(r)
                      this.stateService.clipboardItem = {};
                      this._notificationService.notifySuccess("messages.done");
                    });
                }
              });
            }

          });
        break;
      case "cut":
        this.apiClient.move({ originPath:this.stateService.clipboardItem.follow, destinationPath: this.stateService.panes[targetPane].follow, confirmOverwrite: false}).subscribe(
          (r: string) => {
            this.stateService.addRunningOperation(r)
            this.stateService.clipboardItem = {};
            this._notificationService.notifySuccess("messages.done");
          },
          (error: any) => {
            if (error.status == 409) {

              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: this.screenDetectionService.defaultDialogWidth,
                data: new ConfirmDialogModel(
                  "exclamation-triangle",
                  "Do you want to overwrite \"" + this.stateService.clipboardItem.name + "\"?",
                  "This operation can't be undone.")
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.apiClient.move({ originPath:this.stateService.clipboardItem.follow, destinationPath: this.stateService.panes[targetPane].follow, confirmOverwrite: true}).subscribe(
                    (r: string) => {
                      this.stateService.addRunningOperation(r)
                      this.stateService.clipboardItem = {};
                      this._notificationService.notifySuccess("messages.done");
                    });
                }
              });
            }

          });
        break;
    }

  }

  public onClearClipboardButtonClick() {
    this.stateService.clipboardItem = {};
  }

  public onRefreshPaneButtonClick(targetPane: number) {
    this.followPath(targetPane, this.stateService.panes[targetPane].follow);
  }

}
