import { Component, HostBinding, OnInit } from '@angular/core';
import { GlobalStateService } from './services/global-state.service';
import { LoadingDetectionService } from './services/loading-detection.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public loadingDetectionService: LoadingDetectionService,
    private _globalStateService: GlobalStateService,
    private _translate: TranslateService
  ) {

    _globalStateService.locale = "it";
    _translate.addLangs(['it'])
    _translate.setDefaultLang(_globalStateService.locale);

  }

}
