import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GlobalStateService } from './global-state.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService
{
    constructor(
        private globalStateService: GlobalStateService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private dialog: MatDialog
    ) { }

    public notifyError(i18nMessage: string, data: {} = {})
    {
        return this.snackBar.open(
            this.translate.instant(i18nMessage, data),
            this.translate.instant("actions.ok"),
            {
                duration: 5000,
                panelClass: "error"
            });
    }

    public notifySuccess(i18nMessage: string, data: {} = {})
    {
        return this.snackBar.open(
            this.translate.instant(i18nMessage, data),
            this.translate.instant("actions.ok"),
            {
                duration: 3000,
                panelClass: "success"
            });
    }

}
