// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item.card {
  display: inline-block;
  vertical-align: top;
}
.item.card .details {
  padding: 10px;
}
.item.card circle-progress {
  position: absolute;
  top: 0;
  left: 0;
}

.item.list {
  display: grid;
  grid-template-columns: 120px 2fr 1fr 1fr 1fr 1fr;
  width: 100%;
  position: relative;
}
.item.list circle-progress {
  position: absolute;
  top: 0;
  left: 0;
}
.item.list img {
  width: 100%;
}
.item.list > * {
  padding: 10px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/item-layout/item-layout.component.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,mBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;AAFJ;;AAMA;EACE,aAAA;EACA,gDAAA;EACA,WAAA;EACA,kBAAA;AAHF;AAIE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;AAFJ;AAIE;EACE,WAAA;AAFJ;AAIE;EACE,aAAA;EACA,sBAAA;AAFJ","sourcesContent":["@import \"/src/styles/bp.scss\";\r\n\r\n.item.card {\r\n  display: inline-block;\r\n  vertical-align: top;\r\n\r\n  .details {\r\n    padding: 10px;\r\n  }\r\n\r\n  circle-progress {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n  }\r\n}\r\n\r\n.item.list {\r\n  display: grid;\r\n  grid-template-columns:  120px 2fr 1fr 1fr 1fr 1fr;\r\n  width: 100%;\r\n  position: relative;\r\n  circle-progress {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n  }\r\n  img {\r\n    width: 100%;\r\n  }\r\n  >* {\r\n    padding: 10px;\r\n    box-sizing: border-box;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
