import { Component, OnInit } from '@angular/core';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { LinkedIdentity, Profile, ProfilesClient } from '../../api.client';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sha256 } from 'js-sha256';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {

  public avatars: Map<string, string> = new Map(
    [
      ["Air hostess", "airhostess.png"],
      ["Alien", "alien.png"],
      ["Alieness", "alieness.png"],
      ["Angel", "angel.png"],
      ["Aphrodite", "aphrodite.png"],
      ["Astronaut", "astronaut.png"],
      ["Canary", "canary.png"],
      ["Captainess", "captainess.png"],
      ["Catwoman", "catwoman.png"],
      ["Contractor", "contractor.png"],
      ["Dandy", "dandy.png"],
      ["Devil", "devil.png"],
      ["Doctor", "doctor.png"],
      ["Fairy", "fairy.png"],
      ["Female dummy", "female_dummy.png"],
      ["Gangster", "gangster.png"],
      ["King", "king.png"],
      ["Maid", "maid.png"],
      ["Male dummy", "male_dummy.png"],
      ["Nick", "nick.png"],
      ["Ninja", "ninja.png"],
      ["Nun", "nun.png"],
      ["Nurse", "nurse.png"],
      ["Officer", "officer.png"],
      ["Priest", "priest.png"],
      ["Queen", "queen.png"],
      ["Robot", "robot.png"],
      ["Robotess", "robotess.png"],
      ["Sportsman", "sportsman.png"],
      ["Teacher", "teacher.png"]
    ]
  );

  public profiles: Profile[];

  public newProfile: Profile = {} as Profile;
  public newLinkedIdentity = {} as LinkedIdentity;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    private _profilesClient: ProfilesClient,
    private _notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this._profilesClient.list().subscribe((profiles) => {
      this.profiles = profiles;
    })
  }

  public onRemoveButtonClick(profile: Profile) {
    this._profilesClient.delete({profileId:profile.id}).subscribe(() => {
      this._notificationService.notifySuccess("messages.done");
    });
  }

  public onAddButtonClick() {

    this._profilesClient.add({body:this.newProfile}).subscribe(() => {
      this._profilesClient.list().subscribe(profiles => {
        this.profiles = profiles;
        this._profilesClient.addLinkedIdentity({profileId:this.profiles.find(o => o.displayName == this.newProfile.displayName)?.id, body: this.newLinkedIdentity}).subscribe(() => {

        });
      });
    });
  }

}
