import { Component, OnInit, ViewChild } from '@angular/core';
import { LibraryExplorerLayoutComponent } from '../../layouts/library-explorer-layout/library-explorer-layout.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BookLibraryStateService } from 'src/app/services/library-state/book-library-state.service';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-book-library',
  templateUrl: './book-library.component.html',
  styleUrls: ['./book-library.component.scss']
})
export class BookLibraryComponent implements OnInit {

  @ViewChild(LibraryExplorerLayoutComponent) _libraryExplorerLayout: LibraryExplorerLayoutComponent;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public stateService: BookLibraryStateService,
    ) {

  }

  ngOnInit() {
    if (this.stateService.contentItems.length === 0) {
      this.stateService.contentItemsTotalCount = 999999;
      this.stateService.contentItemsList();
    }
  }

  public onScrolledToBottom() {
    this.stateService.contentItemsList();
  }

  public onSearchTextChanged(searchText: string) {
    this.stateService.contentItemsSearchText = searchText;
    this.stateService.contentItemsList(true);
    this._libraryExplorerLayout.resetScrollPosition();
  }

  public onBasePathChange(event: MatSelectChange) {
    this.stateService.currentBasePath = event.value;
    this.stateService.contentItemsList(true);
    this._libraryExplorerLayout.resetScrollPosition();
  }

  public onViewSliderChange(event: MatSlideToggleChange) {
    this.stateService.contentItemsChangeSelectedView(event.source.name, event.checked);
  }

  public onMarkAsButtonClick(id: number, completed: boolean) {
    this.stateService.contentItemMarkAs(id, completed);
  }

  public onForceInspectButtonClick(id: number) {
    this.stateService.contentItemForceDeepScan(id);
  }

  public onDisplayModeChanged(mode:string) {
    this.stateService.currentDisplayMode = mode;
  }

  public onToDeviceSelectionChange(event: MatOptionSelectionChange, id: number) {
    if (event.isUserInput) {
      this.stateService.toggleToDeviceState(event.source.selected, id, event.source.value);
    }
  }

  public compareToDevices(fromOption: number, fromSelection: number): boolean {
    return fromOption === fromSelection;
  }

}
