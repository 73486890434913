import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ILibraryController, Pageable } from '../../api-client-interfaces';
import { VideoResolutions } from '../../api.client';
import { ConfirmDialogComponent } from '../../bits/confirm-dialog/confirm-dialog.component';
import { UpdatedValueModel } from '../../layouts/item-layout/item-layout.component';
import { ConfirmDialogModel } from '../../models/confirm-dialog.model';
import { GlobalStateService } from '../global-state.service';
import { NotificationService } from '../notification.service';
import { ScreenDetectionService } from '../screen-detection.service';

export interface BaseLibraryItem {
  id?: number;
  title?: string;
  proposedSeriesTitle?: string;
  parent?: BaseLibraryItem;
  year?: number;
  length?: number;
  blackHole?: boolean;
}

export class BaseLibraryStateService<TContainerItem extends BaseLibraryItem, TContentItem extends BaseLibraryItem> {

  private _contentItemsListSubject = new BehaviorSubject<boolean>(false);
  private _containerItemsListSubject = new BehaviorSubject<boolean>(false);

  constructor(
    protected _globalStateService: GlobalStateService,
    protected _storagePrefix: string,
    protected _notificationService: NotificationService,
    protected _dialog: MatDialog,
    protected _screenDetectionService: ScreenDetectionService,
    protected _client: ILibraryController<TContainerItem, TContentItem>
  ) {
    _client.basePaths().subscribe(o => {
      this.basePaths = o;
    });
    this._contentItemsListSubject
      .pipe(debounceTime(1000))
      .subscribe(o => {
        if (o) {
          this._contentItemsList();
        }
      })
    this._containerItemsListSubject
      .pipe(debounceTime(1000))
      .subscribe(o => {
        if (o) {
          this._containerItemsList();
        }
      })
  }

  public get currentDisplayMode(): string {
    return this._globalStateService.getLocalValue<string>(`GLOBAL:CURRENT_DISPLAY_MODE`, "card");
  };

  public set currentDisplayMode(v: string) {
    this._globalStateService.setLocalValue<string>(`GLOBAL:CURRENT_DISPLAY_MODE`, v);
  };

  public basePaths: string[] = [];

  public get currentBasePath(): string {
    return this._globalStateService.getLocalValue<string>(`${this._storagePrefix}:CURRENT_BASE_PATH`, "");
  };

  public set currentBasePath(v: string) {
    this._globalStateService.setLocalValue<string>(`${this._storagePrefix}:CURRENT_BASE_PATH`, v);
    this.contentItemsList(true);
    this.containerItemsList(true);
  };

  public toggleToDeviceState(toDevice: boolean, itemId: number, deviceId: number) {
    if (toDevice) {
      this._client.addToDevice({ itemId: itemId, deviceId: deviceId }).subscribe(() => {
        this.contentItemUpdate(itemId);
        this._notificationService.notifySuccess("messages.done");
      })
    } else {
      this._client.removeFromDevice({ itemId: itemId, deviceId: deviceId }).subscribe(() => {
        this.contentItemUpdate(itemId);
        this._notificationService.notifySuccess("messages.done");
      })
    }
  }

  // Content items

  public contentItems: TContentItem[] = [];
  public contentItemsTotalCount = 999999;
  public contentItemsNextPageIndex = 0;
  public contentItemsInitials: string[];

  public get contentItemsSearchText(): string {
    return this._globalStateService.getLocalValue<string>(`${this._storagePrefix}:CONTENTITEMS:SEARCH_TEXT`, "");
  };

  public set contentItemsSearchText(v: string) {
    this._globalStateService.setLocalValue<string>(`${this._storagePrefix}:CONTENTITEMS:SEARCH_TEXT`, v);
    this.contentItemsList(true);
  };

  public get contentItemsVideoResolution(): VideoResolutions {
    return this._globalStateService.getLocalValue<VideoResolutions>(`${this._storagePrefix}:CONTENTITEMS:VIDEO_RESOLUTION`);
  };

  public set contentItemsVideoResolution(v: VideoResolutions) {
    this._globalStateService.setLocalValue<VideoResolutions>(`${this._storagePrefix}:CONTENTITEMS:VIDEO_RESOLUTION`, v);
    this.contentItemsList(true);
  };

  public get contentItemsSelectedViews(): string[] {
    return (this._globalStateService.getLocalValue<string[]>(`${this._storagePrefix}:CONTENTITEMS:SELECTED_VIEWS`, []));
  };

  public set contentItemsSelectedViews(v: string[]) {
    this._globalStateService.setLocalValue<string[]>(`${this._storagePrefix}:CONTENTITEMS:SELECTED_VIEWS`, v);
    this.contentItemsList(true);
  };

  public contentItemsAddSelectedView(v: string) {
    const sv = this.contentItemsSelectedViews;
    sv.push(v);
    this.contentItemsSelectedViews = sv;
  }

  public contentItemsRemoveSelectedView(v: string) {
    const sv = this.contentItemsSelectedViews;
    sv.splice(this.contentItemsSelectedViews.indexOf(v), 1)
    this.contentItemsSelectedViews = sv;
  }

  public contentItemGetItemById(id: number): TContentItem {
    return this.contentItems.find(o => o.id === id);
  }

  public contentItemsList(reset = false) {
    if (this._currentContentItemsListQuery) {
      this._currentContentItemsListQuery.unsubscribe();
      this._currentContentItemsListQuery = null;
    }

    if (reset) {
      this.contentItemsTotalCount = 999999;
      this.contentItemsNextPageIndex = 0;
      this.contentItems = [];
    }

    if (this.contentItems.length >= this.contentItemsTotalCount) {
      return;
    }

    this._contentItemsListSubject.next(true);
  }

  private _currentContentItemsListQuery: Subscription = null;

  private _contentItemsList() {

    if (this._currentContentItemsListQuery) {
      this._currentContentItemsListQuery.unsubscribe();
      this._currentContentItemsListQuery = null;
    }

    this._currentContentItemsListQuery = this._client.contentItemsList({
      pageNumber:this.contentItemsNextPageIndex,
      pageSize:20,
      filterText:this.contentItemsSearchText,
      filterView:this.contentItemsSelectedViews.join(','),
      basePath:this.currentBasePath,
      containerItemId:this.parentId,
      videoResolution:this.contentItemsVideoResolution
    }).subscribe((r: Pageable<TContentItem>) => {
      this.contentItemsNextPageIndex++;
      this.contentItemsTotalCount = r.count;
      this.contentItems.push(...r.currentPage);
      this.contentItemsInitials = r.initials;
      this._currentContentItemsListQuery = null;
    });
  }

  public contentItemMarkAs(id: number, completed: boolean) {
    this._client.contentItemMarkAs({ contentItemId: id, completed }).subscribe(() => {
      this.contentItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    })
  }

  public contentItemForceDeepScan(id: number) {
    this._client.contentItemForceDeepScan({ contentItemId: id }).subscribe(() => {
      this.contentItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    })
  }

  public contentItemUpdate(id: number) {

    const index = this.contentItems.findIndex(o => o.id === id);

    if (index < 0) {
      return;
    }

    this._client.contentItemsList({
      pageNumber: 0,
      pageSize: 1,
      filterText: this.contentItemsSearchText,
      filterView: this.contentItemsSelectedViews.join(','),
      basePath: this.currentBasePath,
      id: id,
      containerItemId: this.parentId
    })
      .subscribe((r) => {
        if (r.currentPage.length === 0) {
          this.contentItems.splice(index, 1);
          this.contentItemsTotalCount--;
        } else {
          this.contentItems[index] = r.currentPage[0];
        }
      });

    if (this.parentId) {
      this.containerItemUpdate(this.parentId);
    }

  }

  public contentItemsChangeSelectedView(viewName: string, selected: boolean) {
    if (selected) {
      this.contentItemsAddSelectedView(viewName);
    } else {
      this.contentItemsRemoveSelectedView(viewName);
    }
  }

  public contentItemRemove(id: number) {

    const item: TContentItem = this.contentItemGetItemById(id);

    this._dialog.open(ConfirmDialogComponent, {
      width: this._screenDetectionService.defaultDialogWidth,
      data: new ConfirmDialogModel(
        "exclamation-triangle",
        "messages.doYouConfirmRemoval",
        "messages.thisOperationCantBeUndone",
        item)
    }).afterClosed().subscribe(result => {
      if (result) {
        this._client.contentItemRemove({ contentItemId: id }).subscribe(() => {
          this.contentItemUpdate(id);
          this._notificationService.notifySuccess("messages.done");
        })
      }
    });

  }

  public contentItemUpdateProperty(id: number, updatedValueModel: UpdatedValueModel) {

    const item: TContentItem = this.contentItemGetItemById(id);

    if (updatedValueModel.propertyName == "title") {
      if (item.title) {
        updatedValueModel.propertyName = "title";
      } else if (item.proposedSeriesTitle) {
        updatedValueModel.propertyName = "proposedSeriesTitle";
      } else if (this.parent?.title) {
        return;
      }
    }

    this._client.contentItemUpdateProperty({ contentItemId: id, fieldName: updatedValueModel.propertyName, newValue: updatedValueModel.value }).subscribe(() => {
      this.contentItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    });

  }

  // Container items

  public containerItems: TContainerItem[] = [];
  public containerItemsTotalCount = 999999;
  public containerItemsNextPageIndex = 0;
  public containerItemsInitials: string[];

  public get containerItemsSearchText(): string {
    return this._globalStateService.getLocalValue<string>(`${this._storagePrefix}:CONTAINERITEMS:SEARCH_TEXT`, "");
  };

  public set containerItemsSearchText(v: string) {
    this._globalStateService.setLocalValue<string>(`${this._storagePrefix}:CONTAINERITEMS:SEARCH_TEXT`, v);
    this.containerItemsList(true);
  };

  public get containerItemsSelectedViews(): string[] {
    return (this._globalStateService.getLocalValue<string[]>(`${this._storagePrefix}:CONTAINERITEMS:SELECTED_VIEWS`, []));
  };

  public set containerItemsSelectedViews(v: string[]) {
    this._globalStateService.setLocalValue<string[]>(`${this._storagePrefix}:CONTAINERITEMS:SELECTED_VIEWS`, v);
    this.containerItemsList(true);
  };

  public containerItemsAddSelectedView(v: string) {
    const sv = this.containerItemsSelectedViews;
    sv.push(v);
    this.containerItemsSelectedViews = sv;
  }

  public containerItemsRemoveSelectedView(v: string) {
    const sv = this.containerItemsSelectedViews;
    sv.splice(this.containerItemsSelectedViews.indexOf(v), 1)
    this.containerItemsSelectedViews = sv;
  }

  public containerItemGetItemById(id: number): TContainerItem {
    return this.containerItems.find(o => o.id === id);
  }

  public containerItemsList(reset = false) {
    if (this._currentContainerItemsListQuery) {
      this._currentContainerItemsListQuery.unsubscribe();
      this._currentContainerItemsListQuery = null;
    }

    if (reset) {
      this.containerItemsTotalCount = 999999;
      this.containerItemsNextPageIndex = 0;
      this.containerItems = [];
    }

    if (this.containerItems.length >= this.containerItemsTotalCount) {
      return;
    }

    this._containerItemsListSubject.next(true);
  }

  private _currentContainerItemsListQuery: Subscription = null;

  private _containerItemsList() {

    if (this._currentContainerItemsListQuery) {
      this._currentContainerItemsListQuery.unsubscribe();
      this._currentContainerItemsListQuery = null;
    }

    this._currentContainerItemsListQuery = this._client
      .containerItemsList({
        pageNumber: this.containerItemsNextPageIndex,
        pageSize: 20,
        filterText: this.containerItemsSearchText,
        filterView: this.containerItemsSelectedViews.join(','),
        basePath: this.currentBasePath
      }).subscribe((r: Pageable<TContainerItem>) => {
        this.containerItemsNextPageIndex++;
        this.containerItemsTotalCount = r.count;
        this.containerItems.push(...r.currentPage);
        this.containerItemsInitials = r.initials;
        this._currentContainerItemsListQuery = null;
      });

  }

  public containerItemDetails(id: number): Observable<TContainerItem> {
    return this._client.containerItemDetails({ containerItemId: id });
  }

  public containerItemMarkAs(id: number, completed: boolean) {
    this._client.containerItemMarkAs({ containerItemId: id, completed: completed }).subscribe(() => {
      this.containerItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    })
  }

  public containerItemForceDeepScan(id: number) {
    this._client.containerItemForceDeepScan({ containerItemId: id }).subscribe(() => {
      this.containerItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    })
  }

  public containerItemUpdate(id: number) {

    const index = this.containerItems.findIndex(o => o.id === id);

    if (index < 0) {
      return;
    }

    this._client.containerItemsList({
      pageNumber: 0,
      pageSize: 1,
      filterText: this.containerItemsSearchText,
      filterView: this.containerItemsSelectedViews.join(','),
      basePath: this.currentBasePath,
      id: id,
      containerItemId: this.parentId
    }).subscribe((r) => {
      if (r.currentPage.length === 0) {
        this.containerItems.splice(index, 1);
        this.containerItemsTotalCount--;
      } else {
        this.containerItems[index] = r.currentPage[0];
      }
    });

  }

  public containerItemsChangeSelectedView(viewName: string, selected: boolean) {
    if (selected) {
      this.containerItemsAddSelectedView(viewName);
    } else {
      this.containerItemsRemoveSelectedView(viewName);
    }
  }

  public containerItemRemoveById(id: number) {

    const item: TContainerItem = this.containerItemGetItemById(id);

    this._dialog.open(ConfirmDialogComponent, {
      width: this._screenDetectionService.defaultDialogWidth,
      data: new ConfirmDialogModel(
        "exclamation-triangle",
        "messages.doYouConfirmRemoval",
        "messages.thisOperationCantBeUndone",
        item)
    }).afterClosed().subscribe(result => {
      if (result) {
        this._client.containerItemRemove({ containerItemId: id }).subscribe(() => {
          this.containerItemUpdate(id);
          this._notificationService.notifySuccess("messages.done");
        })
      }
    });

  }

  public containerItemRemove(item: TContainerItem): Observable<void> {

    return Observable.create((observer) => {

      this._dialog.open(ConfirmDialogComponent, {
        width: this._screenDetectionService.defaultDialogWidth,
        data: new ConfirmDialogModel(
          "exclamation-triangle",
          "messages.doYouConfirmRemoval",
          "messages.thisOperationCantBeUndone",
          item)
      }).afterClosed().subscribe(result => {
        if (result) {
          this._client.containerItemRemove({ containerItemId: item.id }).subscribe(() => {
            this.containerItemUpdate(item.id);
            this._notificationService.notifySuccess("messages.done");
            observer.next(null);
            observer.complete();
          })
        }
      });

    });

  }

  public containerItemUpdateProperty(id: number, updatedValueModel: UpdatedValueModel) {

    const item: TContainerItem = this.containerItemGetItemById(id);

    this._client.containerItemUpdateProperty({ containerItemId: id, fieldName: updatedValueModel.propertyName, newValue: updatedValueModel.value }).subscribe(() => {
      this.containerItemUpdate(id);
      this._notificationService.notifySuccess("messages.done");
    });

  }



  public parentId?: number;
  public parent?: BaseLibraryItem;



}

