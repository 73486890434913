import { Injectable } from '@angular/core';
import { GlobalStateService } from '../global-state.service';
import { Track } from 'src/app/api.client';

@Injectable()
export class MusicLibraryStateService {

  public get selectedArtistName(): string {
    return this.globalStateService.getLocalValue<string>("MUSICLIBRARY:SELECTED_ARTIST_NAME", "");
  };

  public set selectedArtistName(v: string) {
    this.globalStateService.setLocalValue<string>("MUSICLIBRARY:SELECTED_ARTIST_NAME", v);
  };

  public get selectedAlbumName(): string {
    return this.globalStateService.getLocalValue<string>("MUSICLIBRARY:SELECTED_ALBUM_NAME", "");
  };

  public set selectedAlbumName(v: string) {
    this.globalStateService.setLocalValue<string>("MUSICLIBRARY:SELECTED_ALBUM_NAME", v);
  };

  public get searchText(): string {
    return this.globalStateService.getLocalValue<string>("MUSICLIBRARY:SEARCH_TEXT", "");
  };

  public set searchText(v: string) {
    this.globalStateService.setLocalValue<string>("MUSICLIBRARY:SEARCH_TEXT", v);
  };

  public availableViews: string[] = ["exclude-low-rated", "only-high-rated"];

  public get selectedViews(): string[] {
    return (this.globalStateService.getLocalValue<string[]>("MUSICLIBRARY:SELECTED_VIEWS", []));
  };

  public set selectedViews(v: string[]) {
    this.globalStateService.setLocalValue<string[]>("MUSICLIBRARY:SELECTED_VIEWS", v);
  };

  constructor(public globalStateService: GlobalStateService) {
  }

}

