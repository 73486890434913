import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'unsafeUrl'})
export class UnsafeUrlPipe implements PipeTransform  {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
