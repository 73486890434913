import { Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatDrawerContent } from '@angular/material/sidenav';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { BaseItem, BaseModel } from '../../api.client';
import { ScrollEvent } from '../../directives/scroll-event.directive';

@Component({
  selector: 'app-library-explorer2-layout',
  templateUrl: './library-explorer2-layout.component.html',
  styleUrls: ['./library-explorer2-layout.component.scss']
})
export class LibraryExplorer2LayoutComponent {

  @ViewChild('drawerContent') _drawerContent: MatDrawerContent;

  @Input() public backgroundImage: string;

  @Output() public scrolledToBottom: EventEmitter<void> = new EventEmitter<void>();

  @Input() public currentDisplayMode = "card";
  @Output() public displayModeChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public availableBasePaths: string[] = [];
  @Input() public basePath: string;
  @Output() public basePathChange: EventEmitter<string> = new EventEmitter<string>()

  @Input() public availableViews: string[] = [];
  @Input() public views: string[] = [];
  @Output() public viewsChange: EventEmitter<string[]> = new EventEmitter<string[]>()

  @Input() public availableInitials: string[] = [];
  @Input() public searchText: string;
  @Output() public searchTextChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public exitRoute: string;

  constructor(
    public screenDetectionService: ScreenDetectionService) {
  }

  public onSearchText(text: string) {
    if (this.searchText === text) {
      return;
    }
    this.searchText = text;
    this.searchTextChange.emit(this.searchText);
    this.resetScrollPosition();
  }

  public onChangeDisplayModeClick() {
    this.currentDisplayMode = this.currentDisplayMode === "card" ? "list" : "card";
    this.displayModeChange.emit(this.currentDisplayMode);
  }

  public onDrawerScroll(event: ScrollEvent) {
    if (event.isReachingBottom && !event.isWindowEvent) {
      this.scrolledToBottom.emit();
    }
  }

  public resetScrollPosition() {
    this._drawerContent.scrollTo({ top: 0 });
  }

  public onViewSliderChange(event: MatSlideToggleChange) {
    if (event.checked) {
      if (!this.views.includes(event.source.name)) {
        this.views.push(event.source.name);
        this.viewsChange.emit(this.views);
      }
    } else {
      if (this.views.includes(event.source.name)) {
        this.views.splice(this.views.indexOf(event.source.name), 1)
        this.viewsChange.emit(this.views);
      }
    }
  }

  public onBasePathChange(event: MatSelectChange) {
    this.basePath = event.value;
    this.basePathChange.emit(this.basePath);
    this.resetScrollPosition();
  }

}

