import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'groupByInitial'
})
export class GroupByInitialPipe implements PipeTransform {

  transform(input: Array<string>): Array<any> {

    if (!Array.isArray(input)) {
      return input;
    }

    const arr: { [key: string]: Array<any> } = {};

    for (const value of input) {

      const firstLetter = value.charAt(0).toUpperCase();

      if ((arr[firstLetter]) == undefined) {
        arr[firstLetter] = [];
      }

      arr[firstLetter].push(value);

    }

    return Object.keys(arr).map(key => ({ key, 'value': arr[key] }));
  }
}
