import { Component, OnInit } from '@angular/core';
import { MusicLibraryClient, Track } from 'src/app/api.client';
import { MusicPlayerStateService } from 'src/app/services/music-player-state.service';
import { MusicPlayerStatus } from 'src/app/models/music-player-state.model';
import { Observable } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MusicLibraryStateService } from 'src/app/services/library-state/music-library-state.service';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';

@Component({
  selector: 'app-music-library',
  templateUrl: './music-library.component.html',
  styleUrls: ['./music-library.component.scss']
})
export class MusicLibraryComponent implements OnInit {

  public MusicPlayerStatus = MusicPlayerStatus;

  public artistNames: Array<string>;
  public albumNames: Array<string>;

  constructor(
    private _apiClient: MusicLibraryClient,
    public musicPlayerStateService: MusicPlayerStateService,
    public stateService: MusicLibraryStateService,
    public profileSettingsService: CurrentProfileSettingsService,
    public screenDetectionService: ScreenDetectionService,
    ) {

  }

  ngOnInit() {

    this.loadArtists();
    this.loadAlbums();

    if (this.musicPlayerStateService.tracks.length == 0) {
      this.stateService.selectedViews = ["exclude-low-rated"];
      this.loadTracks();
    }

  }

  public get hasSelectedTags(): boolean {
    return Boolean(this.stateService.selectedAlbumName) || Boolean(this.stateService.selectedArtistName);
  }

  loadArtists() {
    this._apiClient.artists().subscribe((value: string[]) => { this.artistNames = value; });
  }

  loadAlbums() {
    this._apiClient.albums({artistName:this.stateService.selectedArtistName}).subscribe((value: string[]) => { this.albumNames = value; });
  }

  getItems(id?: number | undefined): Observable<Track[]> {
    return this._apiClient.tracks({
      filterText:this.stateService.searchText,
      filterView:this.stateService.selectedViews.join(","),
      artistName:this.stateService.selectedArtistName,
      albumName:this.stateService.selectedAlbumName,
      random:true,
      id
    }
    );
  }

  loadTracks() {
    this.getItems().subscribe((value: Track[]) => {
      this.musicPlayerStateService.tracks = value;
    })
  }

  public onSearchLyricsButtonClick(track: Track) {
  //  const geniusClient = new Genius.Client();
  //  geniusClient.songs.search(`${track.artistName} ${track.title}`).then(searches => {
  //    console.log(searches);
  //  });
  }

  onArtistNameClick(artistName, loadTracks:boolean) {
    if(this.stateService.selectedArtistName != artistName){
      this.stateService.selectedAlbumName = null;
    }
    this.stateService.selectedArtistName = artistName;
    this.loadAlbums();
    if(loadTracks){
      this.loadTracks();
    }
  }

  onAlbumNameClick(artistName, albumName) {
    this.onArtistNameClick(artistName, false);
    this.stateService.selectedAlbumName = albumName;
    this.loadTracks();
  }

  onSearchTextChanged(searchText: string) {
    this.stateService.searchText = searchText;
    this.loadArtists();
    this.loadAlbums();
  }

  onViewsChange(views: string[]) {
    this.stateService.selectedViews = views;
    this.loadTracks();
  }

  onGetNewTracksButtonClick() {
    this.loadTracks();
  }

  onClearTagsButtonClick() {
    this.stateService.selectedAlbumName = null;
    this.stateService.selectedArtistName = null;
    this.loadAlbums();
    this.loadTracks();
  }

  onPlayToggleButtonClick(trackId: number) {
    if (this.musicPlayerStateService.isPlaying) {
      if (trackId == this.musicPlayerStateService.currentTrackId) {
        this.pause();
      } else {
        this.play(trackId);
      }
    } else {
      this.play(trackId);
    }
  }

  public play(trackId: number) {

    let track: Track = this.musicPlayerStateService.tracks.find(o => o.id == trackId);

    if (track) {

      this.musicPlayerStateService.currentTrackId = trackId;
      this.musicPlayerStateService.changeState(track, MusicPlayerStatus.playing);

    } else {

      this.musicPlayerStateService.currentTrackId = 0;
      this.musicPlayerStateService.changeState(null, MusicPlayerStatus.stopped);

    }

  }

  public pause() {
    this.musicPlayerStateService.changeStatus(MusicPlayerStatus.paused);
  }

  onRateTrackButtonClick(trackId: number, ratingDelta: number) {
    this._apiClient.rateTrack({trackId:trackId, rating: ratingDelta}).subscribe(() => this.updateSingleItem(trackId));
  }

  updateSingleItem(id: number) {
    let index: number = this.musicPlayerStateService.tracks.findIndex(o => o.id == id);
    this.getItems(id).subscribe((value: Track[]) => {
      if (value.length == 0) {
        this.musicPlayerStateService.tracks.splice(index, 1);
      } else {
        this.musicPlayerStateService.tracks[index] = value[0];
      }
    });
  }

}
