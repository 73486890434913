import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {

  transform(input: string): string {

    if (!input) {
      return input;
    }

    if (input.length <= 1) {
      return input.toUpperCase();
    }

    const first = input.substring(0, 1);
    const rest = input.substring(1);

    return `${first.toUpperCase()}${rest}`;

  }

}
