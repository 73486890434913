import { Injectable } from '@angular/core';
import { GlobalStateService } from '../global-state.service';
import { Episode, Show, ShowLibraryClient, ShowPageable } from 'src/app/api.client';
import { BaseLibraryStateService } from './base-library-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ScreenDetectionService } from '../screen-detection.service';
import { NotificationService } from '../notification.service';

@Injectable()
export class ShowLibraryStateService extends BaseLibraryStateService<Show, Episode> {

  private _showLibraryClient: ShowLibraryClient;

  public containerItemsViews: string[] =
  [
    "not-seen",
    "published"
  ];

  public contentItemsViews: string[]= ["not-seen"];

  constructor(
    globalStateService: GlobalStateService,
    client: ShowLibraryClient,
    _notificationService: NotificationService,
    dialog: MatDialog,
    screenDetectionService: ScreenDetectionService
  ) {
    super(globalStateService, "SHOWLIBRARY", _notificationService, dialog, screenDetectionService, client);
    this._showLibraryClient = client;
  }

}

