import { Inject, Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Injectable()
export class ScreenDetectionService {

  public isMobile: boolean;

  public defaultDialogWidth: string;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
        this.defaultDialogWidth = this.isMobile ? '300px' : '600px';
      });
  }

}