import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-string-list-editor',
  templateUrl: './string-list-editor.component.html',
  styleUrls: ['./string-list-editor.component.scss']
})
export class StringListEditorComponent implements OnInit {

  @Input() public items: string[] = [];
  @Input() public pattern: string = "";
  @Input() public minlength: number = 0;
  @Input() public itemtitle: string = "Item";


  constructor() { }

  ngOnInit(): void {
  }

}
