import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BookLibraryClient, Book, BookPage, BookBookmark } from 'src/app/api.client';
import { ActivatedRoute, Params } from '@angular/router';
import { BookLibraryStateService } from 'src/app/services/library-state/book-library-state.service';
import { ScrollEvent } from '../../directives/scroll-event.directive';

@Component({
  selector: 'app-book-viewer',
  templateUrl: './book-viewer.component.html',
  styleUrls: ['./book-viewer.component.scss']
})
export class BookViewerComponent implements OnInit {

  public book: Book;
  public bookId: number;
  public bookLength: number;
  public bookPageNumbers: Array<number>;

  public pageNumber?: number;
  public pageBookmarks: { [key: string]: BookBookmark; };
  public pageFistVisibleParagraph: number;

  @ViewChild('pageContentWrapper') pageContentWrapper: ElementRef;
  @ViewChild('pageContent') pageContent: ElementRef;

  constructor(
    public bookLibraryClient: BookLibraryClient, 
    public route: ActivatedRoute,
    public stateService: BookLibraryStateService) { 

  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {

      this.bookId = +params['id'];
      this.pageNumber = params['pageNumber'] ? +params['pageNumber'] : null;

      this.bookLibraryClient.contentItemDetails({contentItemId:this.bookId}).subscribe((book: Book) => {

        this.pageNumber ??= book.progress == 0 ? 1 : book.progress;

        this.bookPageNumbers = Array.from({ length: book.length }, (x, i) => i + 1);

        this.book = book;
        this.bookLength = book.length;

        this.loadPage();

      });

    });
  }

  onPageContentWrapperScroll(event: ScrollEvent) {

    let currentScroll = this.pageContentWrapper.nativeElement.scrollTop;
    let paragraphs = [].slice.call(this.pageContentWrapper.nativeElement.getElementsByTagName("p"));

    let firstVisibleParagraph = paragraphs.find((el) => { return el.offsetTop >= currentScroll });

    if (firstVisibleParagraph) {
      firstVisibleParagraph = parseInt(firstVisibleParagraph.getAttribute("data-paragraph-index"));
    }

    if (firstVisibleParagraph > this.pageFistVisibleParagraph) {
      this.pageFistVisibleParagraph = firstVisibleParagraph;
      this.bookLibraryClient.contentItemUpdateFirstVisibleParagraph({contentItemId:this.bookId,pageNumber: this.pageNumber,firstVisibleParagraph: this.pageFistVisibleParagraph}).subscribe(() => {
        this.stateService.contentItemUpdate(this.bookId)
      });
    }

  }

  onJumpToPageButtonClick(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.loadPage();
  }

  public loadPage() {

    this.bookLibraryClient.contentItemPage({contentItemId:this.bookId, pageNumber:this.pageNumber}).subscribe((bookPage: BookPage) => {

      this.pageBookmarks = bookPage.bookmarks
      this.pageFistVisibleParagraph = bookPage.firstVisibleParagraph;

      this.pageContent.nativeElement.innerHTML = bookPage.content;

      let paragraphs = [].slice.call(this.pageContentWrapper.nativeElement.getElementsByTagName("p"));
      let firstVisibleParagraph = paragraphs.find((el) => { return el.getAttribute("data-paragraph-index") == this.pageFistVisibleParagraph });
      //this.stateService.contentItemUpdate(this.bookId);

      if (firstVisibleParagraph) {
        this.pageContentWrapper.nativeElement.scrollTop = firstVisibleParagraph.offsetTop - 10;
      }

    });

  }

}

