import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiBridgeClient, ComicVineVolume } from 'src/app/api.client';
import { ScreenDetectionService } from '../../services/screen-detection.service';
import { SearchOnMetadataProviderDialogModel } from '../../models/search-on-metadata-provider.model';

@Component({
  selector: 'app-search-on-comicvine-dialog',
  templateUrl: './search-on-comicvine-dialog.component.html',
  styleUrls: ['./search-on-comicvine-dialog.component.scss']
})
export class SearchOnComicVineDialogComponent implements OnInit {

  public searchText:string;
  public items:Array<ComicVineVolume> = new Array<ComicVineVolume>();

  constructor(
    public dialogRef: MatDialogRef<SearchOnComicVineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public model: SearchOnMetadataProviderDialogModel, 
    public apiBridgeClient:ApiBridgeClient) {
  }
 
  ngOnInit() {
  }

  onConfirmComicVineVolumeClick(item){
    this.dialogRef.close(item);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSearchFormSubmit(){
    if(this.searchText == event.target["searchText"].value){
      return;
    }
    this.searchText = event.target["searchText"].value;
    this.apiBridgeClient.searchVolumesByName({name:this.searchText}).subscribe((value:Array<ComicVineVolume>)=>{
      this.items = value;
    });
  }

}
