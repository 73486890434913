import { Component, OnInit } from '@angular/core';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { BookLibraryClient, ComicLibraryClient, CommonClient, MovieLibraryClient, ShowLibraryClient, Statistics } from '../../api.client';
import { GlobalStateService } from '../../services/global-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public statistics: Statistics;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    private _commonClient: CommonClient,
    private _showsClient: ShowLibraryClient,
    private _moviesClient: MovieLibraryClient,
    private _comicsClient: ComicLibraryClient,
    private _booksClient: BookLibraryClient,
    private _globalStateService: GlobalStateService
  ) {
  }

  ngOnInit() {

    this.statistics = {} as Statistics;

    this._booksClient.statistics().subscribe(o => this.statistics.books = o);
    this._showsClient.statistics().subscribe(o => this.statistics.shows = o);
    this._moviesClient.statistics().subscribe(o => this.statistics.movies = o);
    this._comicsClient.statistics().subscribe(o => this.statistics.comics = o);

    //this._commonClient.statistics().subscribe((r: Statistics) => {
    //  this._globalStateService.version = r.version;
    //  this.statistics = r;
    //});

  }

}
