import { Component, OnInit } from '@angular/core';
import { HardwareType } from '../../api.client';
import { CurrentProfileSettingsService } from '../../services/current-profile-settings.service';
import { RealTimeDataService } from '../../services/real-time-data-service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  constructor(
    public realTimeDataService: RealTimeDataService,
    public profileSettingsService: CurrentProfileSettingsService,
  ) { }

  ngOnInit(): void {
  }

  public iconForHardwareType(type: HardwareType) {
    switch (type) {
      case HardwareType.Memory:
        return "la-memory";
      case HardwareType.Cpu:
        return "la-microchip";
      case HardwareType.Storage:
        return "la-hdd";
      default:
        return "la-question";
    }
  }

}
