export class SearchOnMetadataProviderDialogModel{

  public searchText:string;
  public secondaryContent:string;

  constructor(searchText:string, secondaryContent?:string){
    this.searchText = searchText;
    this.secondaryContent=secondaryContent;
  }

}