import { Component, Input, OnInit } from '@angular/core';
import { MediaStreamDescriptor } from '../../api.client';

@Component({
  selector: 'app-stream-descriptor',
  templateUrl: './stream-descriptor.component.html',
  styleUrls: ['./stream-descriptor.component.scss']
})
export class StreamDescriptorComponent implements OnInit {

  @Input() data: MediaStreamDescriptor;

  constructor() { }

  ngOnInit(): void {
  }

}
