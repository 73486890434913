import { Component, OnInit, ViewChild } from '@angular/core';
import { ProposedDownloadPageable, ProposedDownloadsClient } from 'src/app/api.client';
import { LibraryExplorerLayoutComponent } from '../../layouts/library-explorer-layout/library-explorer-layout.component';
import { ScreenDetectionService } from 'src/app/services/screen-detection.service';
import { ProposedDownloadsStateService } from 'src/app/services/proposed-downloads-state.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClipboardService } from 'ngx-clipboard';
import { CurrentProfileSettingsService } from 'src/app/services/current-profile-settings.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-proposed-downloads',
  templateUrl: './proposed-downloads.component.html',
  styleUrls: ['./proposed-downloads.component.scss']
})
export class ProposedDownloadsComponent implements OnInit {

  public nextPageIndex = 0;

  public availableViews: Map<string, string> = new Map([]);

  @ViewChild(LibraryExplorerLayoutComponent) _libraryExplorerLayout: LibraryExplorerLayoutComponent;

  constructor(
    public profileSettingsService: CurrentProfileSettingsService,
    public apiClient: ProposedDownloadsClient,
    public screenDetectionService: ScreenDetectionService,
    public stateService: ProposedDownloadsStateService,
    private _notificationService: NotificationService,
    public clipboardService: ClipboardService) {

  }

  ngOnInit() {
    if (this.stateService.items.length == 0) {
      this.stateService.totalItemsCount = 999999;
      this.getNextItemsPage();
    }
  }

  onScrolledToBottom() {
    this.getNextItemsPage();
  }

  getNextItemsPage(reset = false) {

    if (reset) {
      this.stateService.totalItemsCount = 999999;
      this.nextPageIndex = 0;
    }

    if (this.stateService.items.length >= this.stateService.totalItemsCount) {
      return;
    }

    this.apiClient.list({
      pageNumber:this.nextPageIndex,
      pageSize: 100,
      filterText: this.stateService.searchText,
      filterView: this.stateService.selectedViews.join(',')
    }).subscribe((r: ProposedDownloadPageable) => {
      this.nextPageIndex++;
      this.stateService.totalItemsCount = r.count;
      if (reset) {
        this.stateService.items = [];
      }
      this.stateService.items.push(...r.currentPage)
    });

  }

  onSearchTextChanged(searchText: string) {
    this.stateService.searchText = searchText;
    this.getNextItemsPage(true);
  }

  onViewSliderChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.stateService.addSelectedView(event.source.name);
    } else {
      this.stateService.removeSelectedView(event.source.name);
    }
    this.getNextItemsPage(true);
  }

  onCopyDownloadUrlsButtonClick(downloadUrls: string[]) {
    this.clipboardService.copyFromContent(downloadUrls.map((elem) => elem).join(" "));
    this._notificationService.notifySuccess("messages.done");
  }

  onHideButtonClick(id: number) {
    this.apiClient.setHidden({proposedDownloadId:id,hidden: true}).subscribe(() => {
      this.stateService.updateSingleItem(id);
      this._notificationService.notifySuccess("messages.done");
    })

  }

}
