import { Injectable } from '@angular/core';

@Injectable()
export class GlobalStateService {

  private _keyPrefix = "@ALESSANDRIA";

  public get serverUrls(): string[] {
    return JSON.parse(this.getLocalValue("SERVER_URLS") || "[]");
  };

  public addServerUrl(v: string) {
    v = v.trim();
    const serverUrls = this.serverUrls;
    if (serverUrls.indexOf(v) < 0) {
      serverUrls.push(v);
    }
    this.setLocalValue("SERVER_URLS", JSON.stringify(serverUrls));
  }

  public get apiKeys(): object {
    return JSON.parse(this.getLocalValue("API_KEYS") || "{}");
  };

  public setApiKey(value: string, username: string) {
    if (!this.serverUrl) {
      return;
    }
    const apiKeys = this.apiKeys;
    if (value) {
      apiKeys[this.serverUrl] = { value: value, username: username };
    } else {
      delete apiKeys[this.serverUrl];
    }
    this.setLocalValue("API_KEYS", JSON.stringify(apiKeys));
  }

  public get serverUrl(): string {
    return this.getLocalValue("SERVER_URL", null) ?? document.location.href.split("/").splice(0, 3).join("/");
  };

  public set serverUrl(v: string) {
    this.setLocalValue("SERVER_URL", v);
  };

  public get sessionKey(): string {
    return this.getSessionValue<string>("SESSION_KEY");
  };

  public set sessionKey(v: string) {
    this.setSessionValue("SESSION_KEY", v);
  };

  public get musicPlayerVolume(): number {
    return this.getLocalValue<number>("MUSIC_PLAYER_VOLUME", 0.8);
  };

  public set musicPlayerVolume(v: number) {
    this.setLocalValue("MUSIC_PLAYER_VOLUME", v.toString());
  };

  public get locale(): string {
    return this.getLocalValue<string>(`LOCALE`, "");
  }
  public set locale(v: string) {
    this.setLocalValue<string>(`LOCALE`, v);
  }

  private _getStorageValue<T>(storage: Storage, key: string, valueIfEmpty?: any) {

    const v = storage.getItem(key);
    if (v) {
      const r = JSON.parse(v) as T;
      if (!r) {
        return valueIfEmpty;
      } else {
        return r;
      }
    }
    else {
      return valueIfEmpty;
    }

  }

  private _setStorageValue<T>(storage: Storage, key: string, v: T) {
    if (v == null || v == undefined) {
      storage.removeItem(key);
    } else {
      storage.setItem(key, JSON.stringify(v));
    }
  }

  public getLocalValue<T>(key: string, valueIfEmpty: any = undefined): T {
    return this._getStorageValue(localStorage, `${this._keyPrefix}:${key}`, valueIfEmpty);
  }
  public setLocalValue<T>(key: string, v: T) {
    this._setStorageValue(localStorage, `${this._keyPrefix}:${key}`, v);
  }
  public getSessionValue<T>(key: string, valueIfEmpty?: any): T {
    return this._getStorageValue(sessionStorage, `${this._keyPrefix}:${key}`, valueIfEmpty);
  }
  public setSessionValue<T>(key: string, v: T) {
    this._setStorageValue(sessionStorage, `${this._keyPrefix}:${key}`, v);
  }

  public version: string;

  constructor(
  ) {
  }

}
